import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DebugService } from '@dash/randy/shared/classes/debug.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';

@Directive({
  selector: '[appAccessControlType]',
})
export class AccessControlTypeDirective implements OnInit {
  destroyed$ = new Subject<boolean>();

  @Input() controlType: 'role' | 'permission' = 'role';

  constructor(private elementRef: ElementRef, private tokenStorageService: TokenStorageService) {}

  ngOnInit(): void {
    const app = this.tokenStorageService.getSelectedApplication();
    if (!app) {
      return;
    }

    const accessControlType = app.accessControlType;

    if (this.controlType !== accessControlType) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
