import { FieldRootModel } from '../../field-root/classes/field-root.model';
import { Locale } from '@dash/randy/shared/classes/Locale';

export interface FieldValueModel {
  id?: string;
  name: Locale[];
  fieldRoot?: FieldRootModel;
  /**
   * FieldValueType is a string that represents the type of the field value.
   */
  type: string;
  /**
   * Used for Termen
   */
  uri?: string;
  /**
   * Used for template id for dropdowns
   * Used for min, max and step for numbers
   */
  data?: any;
  dateCreated?: Date;

  // If the field is translatable
  localization?: boolean;
  filter: boolean;
  disallowChangeType: boolean;
  disallowDuplicate: boolean;
  displayType: 'native' | 'advancedSearch';

  defaultHidden?: boolean;
  includeInDefaultSearch?: boolean;
  isUnique: boolean;
  dataSetUris?: string[];

  validationRules?: {
    required: boolean;
    regex?: string;
  };

  prefix?: string;
  suffix?: string;
  representationType?: RepresentationType
}

export type RepresentationType = 'lwh' | 'diaLWH' | 'hwd' | 'diaHWD';

export class FieldValueType {
  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: string) {}

  static readonly TEXT = new FieldValueType('text', $localize`Tekstveld`);
  // static readonly KEYWORD = new FieldValueType('keyword', $localize`Tekstveld (filter)`);
  static readonly BIG_TEXT = new FieldValueType('textArea', $localize`Tekstvak`);
  static readonly URL = new FieldValueType('url', $localize`Url`);
  static readonly FILE = new FieldValueType('file', $localize`Bestand`);
  static readonly GEO_COORDS = new FieldValueType('geo', $localize`Geo coördinaten`);

  static readonly DATE_TIME = new FieldValueType('dateTime', $localize`Datum & tijd`);
  static readonly DATE = new FieldValueType('date', $localize`Datum`);
  static readonly DROPDOWN = new FieldValueType('dropdown', $localize`Keuzelijst`);
  static readonly AUTO_INCREMENT = new FieldValueType('autoIncrement', $localize`Auto nummering`);
  static readonly REMINDER = new FieldValueType('reminder', $localize`Reminder`);
  static readonly PROCEDURE = new FieldValueType('procedure', $localize`Procedure`);

  static readonly DIMENSIONS = new FieldValueType('dimensions', $localize`Dimensies`);
  static readonly NUMBER = new FieldValueType('number', $localize`Nummer`);

  // Only for batch edit
  static readonly OPTION = new FieldValueType('option', $localize`Optie`);

  static getAllTypes(): FieldValueType[] {
    return [
      this.TEXT,
      this.BIG_TEXT,
      this.URL,
      this.DATE,
      this.DATE_TIME,
      this.DROPDOWN,
      this.AUTO_INCREMENT,
      this.GEO_COORDS,
      this.FILE,
      this.REMINDER,
      this.DIMENSIONS,
      this.NUMBER,
    ];
  }

  static getAllAvailableTypesForField(): FieldValueType[] {
    return [
      this.TEXT,
      this.BIG_TEXT,
      this.URL,
      this.DATE,
      this.DATE_TIME,
      this.GEO_COORDS,
      this.FILE,
      this.DIMENSIONS,
      this.NUMBER,
    ];
  }

  static getAllPrivateTypes(): FieldValueType[] {
    return [...this.getAllTypes(), this.PROCEDURE, this.OPTION];
  }

  static getWithText(text: string): FieldValueType {
    return this.getAllPrivateTypes().find(row => row.key === text);
  }

  toString(): string {
    return this.key;
  }
}
