import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {DebugService} from "@dash/randy/shared/classes/debug.service";
import {Subject, takeUntil} from "rxjs";

@Directive({
  selector: '[vid]'
})
export class VideoIDDirective implements OnInit, OnDestroy{
  destroyed$ = new Subject<boolean>();

  get vid(): string {
    return this._vid;
  }

  @Input() set vid(value: string) {
    this._vid = value;
  }

  private _vid: string;

  constructor(private elementRef: ElementRef, private debugService: DebugService) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.setAttribute('data-vid', this.vid);
  }

}
