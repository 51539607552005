import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AddUserInput, UsersModel } from '../../classes/users.model';

export const loadUserss = createAction('[AdminUsers/API] Load users');

export const loadedUserss = createAction('[AdminUsers/API] Loaded users', props<{ users: UsersModel[] }>());

export const addUsers = createAction('[AdminUsers/API] Add Users', props<{ users: AddUserInput }>());

export const addedUsersSuccess = createAction('[AdminUsers/API] Added Users [Success]', props<{ users: UsersModel }>());

export const addedUsersFailed = createAction('[AdminUsers/API] Added Users [Failed]', props<{ users: UsersModel }>());

export const upsertUsers = createAction('[AdminUsers/API] Upsert Users', props<{ users: UsersModel }>());

export const resetUserPassword = createAction('[AdminUsers/API] resetpassword', props<{ user: UsersModel }>());
export const enableUser = createAction('[AdminUsers/API] enable user', props<{ user: UsersModel }>());
export const disableUser = createAction('[AdminUsers/API] disable user', props<{ user: UsersModel }>());
export const signOutUser = createAction('[AdminUsers/API] signOut user', props<{ user: UsersModel }>());

export const addUserss = createAction('[AdminUsers/API] Add users', props<{ users: UsersModel[] }>());

export const upsertUserss = createAction('[AdminUsers/API] Upsert users', props<{ users: UsersModel[] }>());

export const updatedUsersSuccess = createAction(
  '[AdminUsers/API] Updated Users [Success]',
  props<{ users: Update<UsersModel> }>(),
);

export const updatedUsersFailed = createAction(
  '[AdminUsers/API] Updated Users [Failed]',
  props<{ error: any }>(),
);

export const updateUsers = createAction('[AdminUsers/API] Update Users', props<{ users: UsersModel }>());

export const deleteUsers = createAction('[AdminUsers/API] Delete Users', props<{ id: string }>());

export const deletedUsers = createAction('[AdminUsers/API] Deleted Users', props<{ id: string }>());

export const clearUserss = createAction('[AdminUsers/API] Clear users');
