<div class="centerItems">
  <ng-container *ngIf="buttonBeforeText" [ngTemplateOutlet]="buttonBeforeText"></ng-container>
  <h2 class="header" *ngIf="headerText" [innerHTML]="headerText"></h2>
  <ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="buttonAfterText" [ngTemplateOutlet]="buttonAfterText"></ng-container>

  <button *ngIf="videoUrl || tourSteps.length > 0" mat-icon-button class="btn" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <ng-container *ngIf="buttonAfterTextAndMenu" [ngTemplateOutlet]="buttonAfterTextAndMenu"></ng-container>
</div>


<mat-menu #menu>
  <a
    *ngIf="videoUrl"
    target="_blank"
    mat-menu-item
    (click)="showVideo()"
    class="helpButtons"
  >
    <mat-icon color="accent"> play_circle_outline</mat-icon>
    <span i18n>Instructievideo bekijken</span>
  </a>
  <a
    *ngIf="tourSteps.length > 0"
    mat-menu-item
    (click)="startTour()"
    class="helpButtons"
  >
    <mat-icon color="accent"> help_outline</mat-icon>
    <span i18n>Hulp tour starten</span>
  </a>
</mat-menu>
