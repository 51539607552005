<button
  mat-icon-button
  [color]="color"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
  [matTooltip]="ratingId + 1"
  matTooltipPosition="above"
>
  <mat-icon>
    {{ showIcon(i) }}
  </mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0" i18n>
  Aantal sterren is <strong>verplicht</strong> en kan niet nul zijn.
</mat-error>
