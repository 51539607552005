import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[randyGoToHistoryRecord]',
})
export class GoToHistoryRecordDirective {
  get recordName(): string {
    return 'history/' + this._recordName;
  }

  set recordName(value: string) {
    this._recordName = value;
  }

  @Input() private _recordName: string;

  constructor(private router: Router) {}

  // on click handler
  @HostListener('click', ['$event'])
  goToHistoryRecord() {
    if (this._recordName) {
      let url = sessionStorage.getItem(this.recordName);
      if (url) {
        return this.router.navigateByUrl(url);
      }
    }

    let history: string[] = JSON.parse(sessionStorage.getItem('history')) ?? [];
    if (history.length > 0) {
      let url = history.pop();
      sessionStorage.setItem('history', JSON.stringify(history));
      return this.router.navigateByUrl(url);
    }
  }
}
