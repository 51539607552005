<div fxLayout="row" fxLayoutAlign="space-between center">
  <mat-slide-toggle color="accent" (ngModelChange)="publicSliderChanged($event)" [(ngModel)]="isPublic" i18n>Openbaar
  </mat-slide-toggle>
</div>

<div fxLayout="column" *ngIf="!isPublic" class="permCntr">


  <div fxLayout="row" fxLayoutAlign="space-between stretch" class="roleCntr">
    <mat-form-field color="accent">
      <mat-select placeholder="Leesrechten" i18n-placeholder [(ngModel)]="permissionsWithId.read" multiple
                  (ngModelChange)="permissionsChanged()">
        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-select placeholder="Schrijfrechten" i18n-placeholder [(ngModel)]="permissionsWithId.write" multiple
                  (ngModelChange)="permissionsChanged()">
        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <p class="caption" i18n>Selecteer de rollen die <strong>toegang</strong> krijgen. De rollen die niet geselecteerd zijn hebben geen toegang.</p>
</div>
