import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ICrudService } from '@dash/randy/dashboard/services/icrud.service';
import { AuthorizationService } from '../../security/service/authorization.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppInjector } from '@dash/randy/shared/service/app.injector';
import { FilterChipnameService } from '../../advanced-search/services/filter-chipname.service';
import { AuthInterceptor, authInterceptorProviders } from '@dash/randy/security/helpers/auth.interceptor';

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export class DBCrudService<T, S = T> implements ICrudService<T, S> {
  protected userSubject: BehaviorSubject<T>;
  public user: Observable<T>;
  protected authInterceptor: AuthInterceptor;

  constructor(
    protected apiUrl: string,
    protected router: Router,
    protected http: HttpClient,
    protected authorizationService: AuthorizationService,
    protected isAdmin = false,
    protected useGlobalUrl = false,
  ) {
    this.apiUrl = authorizationService.getApiUrl(isAdmin, useGlobalUrl) + apiUrl;
    this.authInterceptor = AppInjector.getInjector().get(AuthInterceptor); // 🌟 DI
  }

  addObject(object: T): Promise<S> {
    return lastValueFrom(this.http.post<S>(`${this.apiUrl}`, JSON.stringify(object), httpOptions));
  }

  updateObject(object: T): Promise<S> {
    return lastValueFrom(this.http.put<S>(`${this.apiUrl}/${object['id']}`, JSON.stringify(object), httpOptions));
  }

  deleteObject(objectId: string): Promise<void> {
    return lastValueFrom(this.http.delete<void>(`${this.apiUrl}/${objectId}`, httpOptions));
  }

  deleteObjects(objectIds: string[]): Promise<void> {
    const promises = objectIds.map(objectId => this.deleteObject(objectId));
    return Promise.all(promises).then(() => {});
  }

  getObject(guid: string): Promise<S> {
    if (!guid) {
      console.error('getObject', guid);
      return Promise.reject('No guid provided');
    }
    return lastValueFrom(this.http.get<S>(`${this.apiUrl}/${guid}`));
  }

  getAllObjects(param: string = null): Promise<S[]> {
    AuthInterceptor.disableErrorRedirection = true;
    let url = `${this.apiUrl}`;
    if (param) {
      url += `/${param}`;
    }

    return lastValueFrom(this.http.get<S[]>(url))
      .catch(error => {
        console.error('Couldnt load all objects', url, error);
        return [];
      })
      .finally(() => {
        AuthInterceptor.disableErrorRedirection = false;
      });
  }
}
