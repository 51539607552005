import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/authorization/pages/account/login/login.component';
import { SelectApplicationComponent } from './modules/authorization/pages/account/select-application/select-application.component';
import { CustomPreloadingStrategyService } from '@dash/randy/shared/classes/custom-preloading-strategy.service';
import { CallbackComponent } from '@dash/randy/security/components/callback/callback.component';
import { ErrorPageComponent } from '@dash/randy/shared/components/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then(m => m.DemoModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'error/:code',
    component: ErrorPageComponent,
  },
  {
    path: 'choose-application',
    component: SelectApplicationComponent,
  },
  {
    path: 'callback/:type',
    component: CallbackComponent,
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
