import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CognitoService } from '@dash/randy/security/service/cognito.service';

@Directive({
  selector: '[appAuthPermissionCheck]',
})
export class AuthPermissionCheckDirective {
  private _permission: string;

  // get permission(): string {
  //   return this._permission;
  // }

  @Input() set appAuthPermissionCheck(value: string | Array<string>) {
    this.validatePermissions(value);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cognitoService: CognitoService,
  ) {}

  private validatePermissions(permissionString: string | Array<string>) {
    if (!this.cognitoService.isAuthenticated()) {
      return false;
    }
    let result = false;

    if (Array.isArray(permissionString)) {
      result = permissionString.some(permission => this.cognitoService.getPermissions(permission));
    } else {
      result = this.cognitoService.getPermissions(permissionString);
    }
    if (result) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
