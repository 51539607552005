import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { CognitoService } from '@dash/randy/security/service/cognito.service';

@Directive({
  selector: '[appAuthPermissionCheckInput]',
})
export class AuthPermissionCheckInputDirective implements AfterViewInit {
  private _permission: string;

  // get permission(): string {
  //   return this._permission;
  // }
  private readonly: boolean = false;

  @Input() set appAuthPermissionCheckInput(value: string) {
    this.validatePermissions(value);
  }

  constructor(private elementRef: ElementRef, private cognitoService: CognitoService) {}

  private validatePermissions(permissionString: string) {
    let result = this.cognitoService.getPermissions(permissionString);
    this.readonly = !result;
  }

  ngAfterViewInit(): void {
    if (this.readonly) {
      this.elementRef.nativeElement.setAttribute('readonly', this.readonly);
      // this.elementRef.nativeElement.setAttribute('readOnly', this.readonly);
    }
  }
}
