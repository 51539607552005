import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FieldTemplateModel } from '../../classes/field-template.model';
import * as FieldTemplateActions from '../actions/field-template.actions';

export const fieldTemplatesFeatureKey = 'fieldTemplates';

export interface FieldTemplatesState extends EntityState<FieldTemplateModel> {
  loaded: boolean;
}

export const adapter: EntityAdapter<FieldTemplateModel> = createEntityAdapter<FieldTemplateModel>();

export const initialState: FieldTemplatesState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(FieldTemplateActions.addedFieldTemplateSuccess, (state, action) => adapter.addOne(action.fieldTemplate, state)),
  on(FieldTemplateActions.upsertFieldTemplate, (state, action) => adapter.upsertOne(action.fieldTemplate, state)),
  on(FieldTemplateActions.addFieldTemplates, (state, action) => adapter.addMany(action.fieldTemplates, state)),
  on(FieldTemplateActions.upsertFieldTemplates, (state, action) => adapter.upsertMany(action.fieldTemplates, state)),
  on(FieldTemplateActions.updatedFieldTemplateSuccess, (state, action) =>
    adapter.updateOne(action.fieldTemplate, state),
  ),
  on(FieldTemplateActions.deletedFieldTemplate, (state, action) => adapter.removeOne(action.id, state)),
  on(FieldTemplateActions.loadedFieldTemplates, (state, action) => {
    return adapter.setAll(action.fieldTemplates, { ...state, loaded: true });
  }),
  on(FieldTemplateActions.loadedFieldTemplateById, (state, action) =>
    adapter.setOne(action.fieldTemplate, { ...state, loaded: true }),
  ),
  on(FieldTemplateActions.clearFieldTemplates, state => adapter.removeAll(state)),
);

export const getFieldTemplatesState = createFeatureSelector<FieldTemplatesState>('fieldTemplates');

export const fieldTemplatesHasLoaded = createSelector(getFieldTemplatesState, (state: FieldTemplatesState) => {
  if (!state) {
    return false;
  }
  return state.loaded;
});

export const getItemById = id => createSelector(getFieldTemplatesState, state => state.entities[id]);
export const getObjects = () =>
  createSelector(selectAll, objects => objects.filter(object => object.type === 'Object'));
export const getLists = () => createSelector(selectAll, objects => objects.filter(object => object.type === 'List'));
export const getProcedures = () =>
  createSelector(selectAll, objects => objects.filter(object => object.type === 'Procedure'));

export const getTemplatesThatContainFieldRootId = (fieldRootId: string, type: string | null = null) =>
  createSelector(selectAll, objects => {
    let results = [];
    if (type) {
      results = objects.filter(object => object.type === type);
    } else {
      results = objects;
    }
    return results.filter(object => object.entries.some(field => field.fieldRootId === fieldRootId));
  });

export const getFieldValue = (fieldTemplateId: string, fieldRootId: string, fieldId: string) =>
  createSelector(getItemById(fieldTemplateId), template => {
    if (!template) {
      return null;
    }
    const fieldRoot = template.entries.find(item => item.fieldRootId === fieldRootId);
    if (!fieldRoot) {
      return null;
    }
    return fieldRoot.fields.find(item => item.fieldId === fieldId) ?? null;
  });

export const getFieldValueDataSetUris = (fieldTemplateId: string, fieldRootId: string, fieldId: string) =>
  createSelector(getFieldValue(fieldTemplateId, fieldRootId, fieldId), field => {
    return field?.dataSetUris ?? [];
  });

export const getFieldValueUri = (fieldTemplateId: string, fieldRootId: string, fieldId: string) =>
  createSelector(getFieldValue(fieldTemplateId, fieldRootId, fieldId), field => {
    return field?.uri ?? null;
  });

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getFieldTemplatesState);
