import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { map, Observable, takeUntil, tap } from 'rxjs';
import {
  FieldTemplateModel,
  FieldTemplateType,
} from '../../../modules/crud/fields/field-template/classes/field-template.model';
import { select, Store } from '@ngrx/store';
import { LocaleUtilService } from '@dash/randy/shared/classes/locale-util.service';
import { selectAll } from '../../../modules/crud/fields/field-template/store/reducers/field-template.reducer';
import { BaseComponent } from '../../classes/baseComponent';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import {COLLECTION_LOCALE} from "@dash/randy/shared/shared.module";

@Component({
  selector: 'randy-select-object-type',
  templateUrl: './select-object-type.component.html',
  styleUrls: ['./select-object-type.component.scss'],
})
export class SelectObjectTypeComponent extends BaseComponent implements OnInit {
  @Input() placeholder: string = 'Objecttype';
  @Input() templateId: string;
  @Output() templateIdChange = new EventEmitter<string>();

  templates: Observable<FieldTemplateModel[]>;
  templatesFiltered$: Observable<Map<FieldTemplateType, FieldTemplateModel[]>>;
  typeFormControl: FormControl = new FormControl('');

  typeTranslationMap = {
    Object: $localize`Objecttype`,
    List: $localize`Keuzelijst`,
    Procedure: $localize`Procedure`,
  }

  constructor(
    private templateStore: Store<FieldTemplateModel>,
    private localeUtil: LocaleUtilService,
    @Inject(COLLECTION_LOCALE) public collectionLocale: string,
  ) {
    super();
  }

  ngOnInit(): void {
    this.templates = this.templateStore.pipe(
      select(selectAll),
      filter(templates => templates.length > 0),
      takeUntil(this.destroyed$),
      map(templates => {
        return templates.sort((a, b) => {
          let aName = this.localeUtil.getFromLocales(a.name, this.collectionLocale)?.data;
          let bName = this.localeUtil.getFromLocales(b.name, this.collectionLocale)?.data;
          return aName.localeCompare(bName);
        });
      }),
    );
    this.templatesFiltered$ = this.templates.pipe(map(this.convertTemplatesToTypeMap));

    this.typeFormControl.setValue(this.templateId);

    this.typeFormControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.templateId = value;
      this.templateIdChange.next(value);
    });
  }

  convertTemplatesToTypeMap(templates) {
    let types = new Map<FieldTemplateType, FieldTemplateModel[]>();
    templates.forEach(template => {
      if (!types.has(template.type)) {
        types.set(template.type, []);
      }
      types.get(template.type).push(template);
    });
    return types;
  }

  filterDropdownObjectTypes(searchText: string) {
    if (!searchText) {
      this.templatesFiltered$ = this.templates.pipe(map(this.convertTemplatesToTypeMap));
    }
    this.templatesFiltered$ = this.templates.pipe(
      map(templates =>
        templates.filter(template => {
          let name = this.localeUtil.getFromLocales(template.name, this.collectionLocale)?.data;
          return name?.toLowerCase()?.includes(searchText.toLowerCase());
        }),
      ),
      map(templates => {
        return templates.sort((a, b) => {
          let aName = this.localeUtil.getFromLocales(a.name, this.collectionLocale)?.data;
          let bName = this.localeUtil.getFromLocales(b.name, this.collectionLocale)?.data;
          return aName.localeCompare(bName);
        });
      }),
      map(this.convertTemplatesToTypeMap),
      tap(templates => {
        console.log('Templates', templates);
      }),
    );
  }
}
