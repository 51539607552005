import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CollectionItemModel } from '../../modules/crud/collections/collection-item/classes/collection-item.model';
import {
  collectionItemsHasLoaded,
  getItemById,
} from '../../modules/crud/collections/collection-item/store/reducers/collection-item.reducer';
import { filter, take, takeWhile } from 'rxjs/operators';
import { Locale } from '@dash/randy/shared/classes/Locale';
import {firstValueFrom, Observable} from 'rxjs';

@Pipe({
  name: 'collectionItemField',
})
export class CollectionItemFieldPipe implements PipeTransform {
  constructor(private collectionItemStore: Store<CollectionItemModel>) {}

  transform(collectionItemId: string, ...fields: string[]): Observable<CollectionItemModel> {
    // return firstValueFrom(
    //   this.collectionItemStore.select(getItemById(collectionItemId)).pipe(
    //     filter(item => !!item),
    //     take(1),
    //   ),
    // );
    return this.collectionItemStore.select(getItemById(collectionItemId)).pipe(
      filter(item => !!item),
    )
    // console.log(item.name, 'Item');
    // return item.name;
  }
}
