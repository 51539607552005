import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FieldRootModel } from '../../classes/field-root.model';
import * as FieldRootActions from '../actions/field-root.actions';
import { FieldTemplateType } from '../../../field-template/classes/field-template.model';
import { FieldValueModel } from '../../../field-value/classes/field-value.model';

export const fieldRootsFeatureKey = 'fieldRoots';

export interface FieldRootsState extends EntityState<FieldRootModel> {
  loaded: boolean;
}

export const adapter: EntityAdapter<FieldRootModel> = createEntityAdapter<FieldRootModel>();

export const initialState: FieldRootsState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(FieldRootActions.addedFieldRootSuccess, (state, action) => adapter.addOne(action.fieldRoot, state)),
  on(FieldRootActions.upsertFieldRoot, (state, action) => adapter.upsertOne(action.fieldRoot, state)),
  on(FieldRootActions.addFieldRoots, (state, action) => adapter.addMany(action.fieldRoots, state)),
  on(FieldRootActions.upsertFieldRoots, (state, action) => adapter.upsertMany(action.fieldRoots, state)),
  on(FieldRootActions.updatedFieldRootSuccess, (state, action) => adapter.updateOne(action.fieldRoot, state)),
  on(FieldRootActions.deletedFieldRoot, (state, action) => adapter.removeOne(action.id, state)),
  on(FieldRootActions.loadedFieldRoots, (state, action) => {
    console.log('Full state', state);
    return adapter.setAll(action.fieldRoots, { ...state, loaded: true });
  }),
  on(FieldRootActions.clearFieldRoots, state => adapter.removeAll(state)),
);

export const getFieldRootsState = createFeatureSelector<FieldRootsState>(fieldRootsFeatureKey);
export const selectFieldRootsState = (state: any) => state[fieldRootsFeatureKey];
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getFieldRootsState);

export const fieldRootsHasLoaded = createSelector(selectFieldRootsState, (state: FieldRootsState) => state?.loaded);

export const getItemById = id => createSelector(getFieldRootsState, state => state.entities[id]);

export const getAllForType = (type: FieldTemplateType) => {
  return createSelector(selectAll, (fieldRootModels) => {
    return fieldRootModels.filter(item => {
      if (!item.templateTypes || item.templateTypes.length === 0) {
        return true;
      }
      return item.templateTypes.includes(type);
    });
  });
};

export const getAllForObjects = createSelector(getAllForType('Object'), state => state);
export const getAllForProcedures = () => createSelector(getAllForType('Procedure'), state => state);
export const getAllForRegisters = () => createSelector(getAllForType('List'), state => state);

export const getAllFilters = createSelector(fieldRootsHasLoaded, getAllForObjects, (loaded, fieldRootModels) => {
  console.log('loaded', loaded);
  if(!loaded) {
    return [];
  }
  const filters = fieldRootModels.map(item => {
      return item.fields.filter(field => field.filter);
    })
    .flat(Infinity);
  return filters as FieldValueModel[];
});
