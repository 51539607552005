import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError, catchError, take } from 'rxjs';
import { Router } from '@angular/router';
import { CognitoService } from '@dash/randy/security/service/cognito.service';
import * as Sentry from '@sentry/angular';
import { OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end
// const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements OAuthResourceServerErrorHandler {
  constructor(
    private cognitoService: CognitoService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private oauthService: OAuthService,
  ) {
    console.log('AuthInterceptor initialized');
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   let authReq = req;
  //   // const token = this.cognitoService.getAccessToken();
  //   let token = null;
  //   if (token != null) {
  //     // for Spring Boot back-end
  //     // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  //     // for Node.js Express back-end
  //     // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
  //   }
  //   return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
  // }

  private handleAuthError(err: HttpResponse<any>): Observable<any> {
    if(!environment.production){
        console.log('Error in AuthInterceptor', err);
        return throwError(() => err);
    }
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      Sentry.setContext('userToken', {
        // accessToken: this.cognitoService.getAccessToken(),
        // refreshToken: this.oauthService.getRefreshToken(),
        expiresAt: {
          number: this.oauthService.getAccessTokenExpiration(),
          date: new Date(this.oauthService.getAccessTokenExpiration()),
        },
        validAccessToken: this.oauthService.hasValidAccessToken(),
      });

      console.log(err, 'Unauthorized!');

      return throwError(() => err);
      //navigate /delete cookies or whatever
      // this.router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
    } else if (!AuthInterceptor.disableErrorRedirection && err.status >= 500 && err.status < 600) {
      console.log(err, 'Server error!');
      console.log('Redirect to error page', AuthInterceptor.disableErrorRedirection);

      this.router.navigate([`/error/${err.status}`]).then(() => {});
      return throwError(() => err);
    } else {
      return throwError(() => err);
    }
    // else if (err.status === 400 || err.status === 500) {
    //   console.log('Show sentry dialog');
    //   const eventId = Sentry.captureException(err.error);
    //
    //   Sentry.showReportDialog({
    //     eventId: eventId,
    //     lang: 'nl',
    //   });
    //   return of(err.message);
    // }
  }

  handleError(err: HttpResponse<any>): Observable<any> {
    return this.handleAuthError(err);
  }

  static disableErrorRedirection = false;
}

export const authInterceptorProviders = [
  { provide: OAuthResourceServerErrorHandler, useClass: AuthInterceptor, multi: false },
];
