import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { DragAndDropOverlayComponent } from '@dash/randy/shared/components/drag-and-drop-overlay/drag-and-drop-overlay.component';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective implements OnInit {
  @Output() fileUploaded = new EventEmitter<{ index: number; files: DataTransferItemList }>();

  @HostBinding('class.isDropping') isDropping = false;

  private overlay: ComponentRef<DragAndDropOverlayComponent> = null;

  private dropCounter = 0;

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    // factory comp resolver
    let factory = this.resolver.resolveComponentFactory(DragAndDropOverlayComponent);

    // create component
    this.overlay = this.viewContainerRef.createComponent(factory);
    this.el.nativeElement.appendChild(this.overlay.location.nativeElement);
  }

  @HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropCounter++;

    this.isDropping = this.dropCounter > 0;
    if (this.isDropping) {
      let target = (evt.target as Element).closest('tr');
      if (target) {
        let attr = target.getAttribute('data-isFolder');
        if (attr === 'true') {
          target.classList.add('rowDropping');
        }
      }
    }
    this.overlay.instance.changeOverlay(this.isDropping);
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
    evt.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.dropCounter--;
    this.isDropping = this.dropCounter > 0;
    let target = (evt.target as Element).closest('tr');
    if (target) {
      let attr = target.getAttribute('data-isFolder');
      if (attr === 'true') {
        target.classList.remove('rowDropping');
      }
    }
    this.overlay.instance.changeOverlay(this.isDropping);
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.dropCounter = 0;
    this.isDropping = this.dropCounter > 0; // So just false?
    this.overlay.instance.changeOverlay(this.isDropping);
    const files = evt.dataTransfer.items;

    let index = this.getTableRow(evt);

    if (files.length > 0) {
      this.fileUploaded.emit({ index: index, files: files });
    }
  }

  private getTableRow(evt: DragEvent): number {
    let target = evt.target as Element;
    let tr = target.closest('tr');
    if (!tr) {
      return -1;
    }
    return tr.rowIndex;
  }
}
