import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsSettingsService } from '../../service/google-maps-settings.service';

@NgModule({
  declarations: [],
  exports: [HttpClientModule, HttpClientJsonpModule, GoogleMapsModule],
  providers: [
    // GoogleMapsSettingsService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: GoogleMapsSettingsService) => () => service.initApi(),
    //   deps: [GoogleMapsSettingsService],
    //   multi: false,
    // },
  ],
})
export class GoogleMapsSharedModule {
  // constructor(private googleMapsSettings: GoogleMapsSettingsService) {
  //   console.log('Google maps shared module init');
  //   this.googleMapsSettings.initApi();
  // }

  static forRoot(): ModuleWithProviders<GoogleMapsSharedModule> {
    return {
      ngModule: GoogleMapsSharedModule,
      providers: [GoogleMapsSettingsService],
    };
  }
}
