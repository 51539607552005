import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DebugService } from '@dash/randy/shared/classes/debug.service';
import { Observable, Subject, map, shareReplay } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Directive({
  selector: '[appHideOnMobile]',
})
export class HideOnMobileDirective implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  @Input() displaySize: keyof typeof Breakpoints = 'Handset';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints[this.displaySize]).pipe(
    map(result => result.matches),
    shareReplay(),
  );

  constructor(
    private elementRef: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.isHandset$.pipe(takeUntil(this.destroyed$)).subscribe(isHandset => {
      if (isHandset) {
        this.viewContainer.clear();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
