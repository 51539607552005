import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalstorageRepoService } from '@dash/randy/shared/classes/localstorage-repo.service';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  isDemo$ = new BehaviorSubject(false);

  constructor(private localStorageService: LocalstorageRepoService) {
    let isDemoLocal = this.localStorageService.getItem('isDemo', localStorage);

    console.log('isDemoLocal', isDemoLocal);
    let isDemo = this.convertDemoValueToBoolean(isDemoLocal);
    console.log('isDemo', isDemo);
    this.setDemo(isDemo);
  }

  convertDemoValueToBoolean(value: string): boolean {
    if (!value) {
      return false;
    }
    return value === 'ja';
  }

  convertDemoBooleanToValue(value: boolean): string {
    return value ? 'ja' : 'nee';
  }

  setDemo(value: boolean) {
    console.log('Setting demo to', value);
    this.localStorageService.setItem('isDemo', this.convertDemoBooleanToValue(value), localStorage);
    this.isDemo$.next(value);
  }

  isDemo(): Observable<boolean> {
    return this.isDemo$.asObservable();
  }

  isDemoSync(): boolean {
    return this.isDemo$.getValue();
  }
}
