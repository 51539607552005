import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[hideProd]',
})
export class HideProdDirective implements OnInit {
  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    if (!environment.production) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
