<div class="video-wrapper">
  <video #videoPlayer controls *ngIf="videoFile.type === 'VIDEO'">
    <!--          <source [src]="item |  videoCdnLink" type="video/mp4">-->
    Your browser does not support the video tag.
  </video>
  <audio #audioPlayer controls *ngIf="videoFile.type === 'AUDIO'">
    <source [src]="videoFile | audioCdnLink" type="audio/mpeg">
    Your browser does not support the audio tag.
  </audio>

  <button class="close" mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
