import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LogService } from '@dash/randy/shared/classes/log.service';
import { LocalstorageRepoService } from '@dash/randy/shared/classes/localstorage-repo.service';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-mat-table-header-toolbar',
  templateUrl: './mat-table-header-toolbar.component.html',
  styleUrls: ['./mat-table-header-toolbar.component.scss'],
})
export class MatTableHeaderToolbarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  searchForm: FormGroup;
  @Output() keyUpEventSearchbar = new EventEmitter<KeyboardEvent>();

  @Input() dataSource: MatTableDataSource<any>;
  private key: string;

  private loaded = new BehaviorSubject<boolean>(false);
  private destroyed$ = new Subject<boolean>();
  private pageSubscription: Subscription;
  private sortSubscription: Subscription;
  private pageInit = false;
  private sortInit = false;
  @Input() filterPredicate = (data, filter) => {
    const dataStr = JSON.stringify(data).toLowerCase();
    return dataStr.indexOf(filter) !== -1;
  };

  constructor(
    private fb: FormBuilder,
    private viewContainerRef: ViewContainerRef,
    private localStorageService: LocalstorageRepoService,
    private logService: LogService,
  ) {}

  ngOnInit(): void {
    this.localStorageService.setStorage(sessionStorage);
    this.key = this.viewContainerRef['_hostLView'][0].localName;

    if (!this.searchForm) {
      let filter = sessionStorage.getItem(this.key);
      this.searchForm = this.fb.group({
        data: [filter ?? '', null],
      });

      this.loaded.subscribe(data => {
        if (data) {
          this.filter(this.searchForm.get('data').value);
        }
      });
    }
  }

  keyUpEventHandler($event: KeyboardEvent): void {
    this.keyUpEventSearchbar.emit($event);
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.filter(filterValue);
    }
  }

  filter(filterValue: string): void {
    sessionStorage.setItem(this.key, filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.dataSource.filterPredicate = this.filterPredicate;
      if (this.dataSource.paginator) {
        this.initPaginationSaver();
      }
      if (this.dataSource.sort) {
        this.initSortSaver();
      }
      this.loaded.next(true);
    }
  }

  clearSearchText() {
    this.searchForm.get('data').setValue('');
    this.filter('');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initPaginationSaver();
      this.initSortSaver();
    });
  }

  private pageKey() {
    return this.key + 'page';
  }

  private sortKey() {
    return this.key + 'sort';
  }

  private saveSortData(sort: Sort) {
    let data = { ...sort };
    this.localStorageService.setItem(this.sortKey(), JSON.stringify(data));
  }

  private getSortData(): Sort {
    return JSON.parse(this.localStorageService.getItem(this.sortKey()));
  }

  private initSortSaver() {
    if (!this.dataSource.sort) {
      console.log('No sort');
      return;
    }
    if (!this.sortInit) {
      this.sortInit = true;
      let sortData = this.getSortData();
      if (sortData) {
        this.dataSource.sort.active = sortData.active;
        this.dataSource.sort.direction = sortData.direction;
        this.dataSource._updateChangeSubscription();
      }
    }

    this.sortSubscription && this.sortSubscription.unsubscribe();
    this.sortSubscription = this.dataSource.sort.sortChange.subscribe(sort => {
      console.log(sort);
      this.saveSortData(sort);
    });
  }

  private savePaginationData(page: PageEvent) {
    let data = { ...page };
    delete data.length;
    delete data.previousPageIndex;
    this.localStorageService.setItem(this.pageKey(), JSON.stringify(data));
  }

  private getPaginationData(): {
    pageIndex: number;
    pageSize: number;
  } {
    return JSON.parse(this.localStorageService.getItem(this.pageKey()));
  }

  private initPaginationSaver() {
    if (!this.dataSource.paginator) {
      console.log('No paginator');
      return;
    }
    if (!this.pageInit) {
      this.pageInit = true;
      let pageData = this.getPaginationData();
      if (pageData) {
        this.dataSource.paginator.pageSize = pageData.pageSize;
        this.dataSource.paginator.pageIndex = pageData.pageIndex;
        this.dataSource._updateChangeSubscription();
      }
    }

    this.pageSubscription && this.pageSubscription.unsubscribe();
    this.pageSubscription = this.dataSource.paginator.page.subscribe(page => {
      console.log(page);
      this.savePaginationData(page);
    });
  }
}
