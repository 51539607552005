import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ApplicationsModel } from '../../classes/applications.model';

export const loadApplicationss = createAction('[Applications/API] Load Applicationss');

export const loadedApplicationss = createAction(
  '[Applications/API] Loaded Applicationss',
  props<{ applicationss: ApplicationsModel[] }>(),
);

export const addApplications = createAction(
  '[Applications/API] Add Applications',
  props<{ applications: ApplicationsModel }>(),
);

export const addedApplicationsSuccess = createAction(
  '[Applications/API] Added Applications [Success]',
  props<{ applications: ApplicationsModel }>(),
);

export const addedApplicationsFailed = createAction(
  '[Applications/API] Added Applications [Failed]',
  props<{ applications: ApplicationsModel }>(),
);

export const upsertApplications = createAction(
  '[Applications/API] Upsert Applications',
  props<{ applications: ApplicationsModel }>(),
);

export const addApplicationss = createAction(
  '[Applications/API] Add Applicationss',
  props<{ applicationss: ApplicationsModel[] }>(),
);

export const upsertApplicationss = createAction(
  '[Applications/API] Upsert Applicationss',
  props<{ applicationss: ApplicationsModel[] }>(),
);

export const updatedApplicationsSuccess = createAction(
  '[Applications/API] Updated Applications [Success]',
  props<{ applications: Update<ApplicationsModel> }>(),
);

export const updatedApplicationsFailed = createAction(
  '[Applications/API] Updated Applications [Failed]',
  props<{ applications: Update<ApplicationsModel> }>(),
);

export const updateApplications = createAction(
  '[Applications/API] Update Applications',
  props<{ applications: ApplicationsModel }>(),
);

export const deleteApplications = createAction('[Applications/API] Delete Applications', props<{ id: string }>());

export const deletedApplications = createAction('[Applications/API] Deleted Applications', props<{ id: string }>());

export const clearApplicationss = createAction('[Applications/API] Clear Applicationss');
