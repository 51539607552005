import { Component, OnInit } from '@angular/core';
import { CognitoService } from '@dash/randy/security/service/cognito.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(
    private oathService: OAuthService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    console.log('Received callback');
    const params = this.route.snapshot.queryParams;
    console.log(params, 'Route params');

    this.route.url.subscribe(params => {
      const path = params[1].path;
      switch (path) {
        case 'login':
          this.handleLogin();
          break;
        case 'logout':
          this.cognitoService.signOut();
          this.router.navigate(['/login']).then(() => {});
          break;
      }
    });
  }

  private async handleLogin() {
    console.log('Handle login');
    await this.cognitoService.handleCallBack();
    // this.router.navigate(['/dashboard', 'home']);
    this.router.navigate(['/choose-application']).then(() => {});
  }
}
