import { Pipe, PipeTransform } from '@angular/core';
import { ItemValueDataModel } from '../../modules/crud/items/item-value/classes/item-value.model';
import { FieldValueType } from '../../modules/crud/fields/field-value/classes/field-value.model';
import { DataTypeHelperFactory } from '@dash/randy/shared/service/collection-items/data-type-helper-factory';

@Pipe({
  name: 'collectionItemDataDisplay',
})
export class CollectionItemDataDisplayPipe implements PipeTransform {
  transform(value: ItemValueDataModel, type: FieldValueType, locale?: string): string {
    const dataTypeHelper = DataTypeHelperFactory.getDataTypeHelper(type);
    return dataTypeHelper.getDataAsString(value, locale);
  }
}
