<app-spinner *ngIf="submitted"></app-spinner>
<!--<form [formGroup]="form" (ngSubmit)="onSubmit()" [hidden]="submitted">-->
<!--  <mat-card class="login-card">-->
<!--    <mat-card-header>-->
<!--      <div class="loginHeader">-->
<!--        <img src="https://kleksi.com/img/logo_kleksi_app_white.png" class="logoImg">-->
<!--        <p>COLLECTION MANAGEMENT SYSTEM</p>-->
<!--      </div>-->
<!--    </mat-card-header>-->

<!--    <mat-card-content>-->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <mat-form-field color="accent" class="full-width">-->
<!--            <input matInput placeholder="Gebruikersnaam" formControlName="username">-->
<!--            <mat-error *ngIf="form.controls['username'].hasError('required')">-->
<!--              Name is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <mat-form-field color="accent" class="full-width">-->
<!--            <input matInput type="password" placeholder="Wachtwoord" formControlName="password">-->
<!--            <mat-error *ngIf="form.controls['password'].hasError('required')">-->
<!--              Path is <strong>required</strong>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-card-content>-->

<!--    <mat-card-actions>-->
<!--      <button mat-raised-button color="accent" type="submit">Login</button>-->
<!--      <a *ngIf="passwordForgotLink != null" [routerLink]="passwordForgotLink" mat-flat-button class="passwordForgot">Wachtwoord vergeten</a>-->
<!--    </mat-card-actions>-->
<!--  </mat-card>-->
<!--</form>-->
<mat-card class="login-card">
  <mat-card-actions>
    <button mat-flat-button (click)="handleLoginBtn()" i18n>Login</button>
  </mat-card-actions>
</mat-card>
