<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <p class="messageCntr">{{message}}</p>
  <ng-container *ngIf="password">
    <p i18n>Herhaal het volgende woord voordat dit definitief verwijderd wordt: "{{password}}"</p>

    <mat-form-field color="accent" appearance="fill">
      <mat-label i18n>Voer het woord in</mat-label>
      <input matInput [(ngModel)]="passwordInput"
             (keydown.enter)="handleConfirm()"
             (ngModelChange)="inputChanged($event)">
    </mat-form-field>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="warn" [disabled]="!enableConfirmButton" (click)="confirmed()" i18n>Ja!</button>
  <button mat-flat-button color="accent" mat-dialog-close i18n>Nee</button>
</div>
