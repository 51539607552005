import {Inject, Pipe, PipeTransform} from '@angular/core';
import { Locale } from '@dash/randy/shared/classes/Locale';
import * as Sentry from '@sentry/angular';
import {COLLECTION_LOCALE} from "@dash/randy/shared/shared.module";

@Pipe({
  name: 'simpleLocale',
})
export class LocaleSimplePipe implements PipeTransform {
  constructor(
    @Inject(COLLECTION_LOCALE) private collectionLocaleId: string,
  ) {}

  transform(value: Locale[], lang?: string): string {
    if (!value) {
      // console.error(value, 'Value isnt found for locale pipe!');
      // Sentry.captureMessage('Value isnt found for locale pipe!', {
      //   level: Sentry.Severity.Warning,
      //   extra: {
      //       value,
      //       lang,
      //   }
      // });
      return ('Waiting for translation');
    }
    if (value.length === 0) {
      console.error(value, 'This value doenst have any records!');
      return 'No text found';
    }
    if (!lang) {
      lang = this.collectionLocaleId;
    }
    let localeObj = value.find(locale => locale.locale === lang);
    if (localeObj) {
      return localeObj.data;
    }
    console.warn(value, 'Locale "' + lang + '" isn\'t found!');
    return value[0].data;
  }
}
