import { Component, Input, OnInit } from '@angular/core';
import { SmallStatusEnum, StatusService } from '@dash/randy/shared/classes/status.service';
import { LogService } from '@dash/randy/shared/classes/log.service';

@Component({
  selector: 'app-small-status',
  templateUrl: './small-status.component.html',
  styleUrls: ['./small-status.component.scss'],
})
export class SmallStatusComponent implements OnInit {
  @Input() status: SmallStatusEnum = null;
  @Input() statusKey = 'globalStatus';

  constructor(private statusService: StatusService, private logService: LogService) {}

  ngOnInit(): void {
    this.statusService.getStatus(this.statusKey).subscribe(data => {
      this.logService.debug('New status received', data);
    });
  }

  public get SmallStatusEnum() {
    return SmallStatusEnum;
  }
}
