import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {CollectionItemModel, TermData} from '../../classes/collection-item.model';
import { ItemValueModel } from '../../../../items/item-value/classes/item-value.model';
import { ItemValueRootModel } from '../../../../items/item-value/classes/item-value-root-model';

export const loadCollectionItems = createAction('[CollectionItem/API] Load CollectionItems');

export const loadedCollectionItems = createAction(
  '[CollectionItem/API] Loaded CollectionItems',
  props<{ collectionItems: CollectionItemModel[]; hasCustomId: boolean }>(),
);

export const loadCollectionItemsWithTemplateId = createAction(
  '[CollectionItem/API] Load CollectionItem with TemplateId',
  props<{ templateId: string }>(),
);

export const loadCollectionItem = createAction(
  '[CollectionItem/API] Start load CollectionItem',
  props<{ collectionItemId: string }>(),
);

export const loadedCollectionItemsWithTemplateId = createAction(
  '[CollectionItem/API] Loaded CollectionItem with TemplateId',
  props<{ templateId: string; collectionItems: CollectionItemModel[] }>(),
);

export const addCollectionItem = createAction(
  '[CollectionItem/API] Add CollectionItem',
  props<{ collectionItem: CollectionItemModel }>(),
);

export const addedCollectionItemSuccess = createAction(
  '[CollectionItem/API] Added CollectionItem [Success]',
  props<{ collectionItem: CollectionItemModel }>(),
);

export const addedCollectionItemFailed = createAction(
  '[CollectionItem/API] Added CollectionItem [Failed]',
  props<{ error: any }>(),
);

export const loadedCollectionItemFailed = createAction(
  '[CollectionItem/API] Loaded CollectionItem [Failed]',
  props<{ error: any }>(),
);

export const upsertCollectionItem = createAction(
  '[CollectionItem/API] Upsert CollectionItem',
  props<{ collectionItem: CollectionItemModel }>(),
);

export const addCollectionItems = createAction(
  '[CollectionItem/API] Add CollectionItems',
  props<{ collectionItems: CollectionItemModel[] }>(),
);

export const upsertCollectionItems = createAction(
  '[CollectionItem/API] Upsert CollectionItems',
  props<{ collectionItems: CollectionItemModel[] }>(),
);

export const updatedCollectionItemSuccess = createAction(
  '[CollectionItem/API] Updated CollectionItem [Success]',
  props<{ collectionItem: Update<CollectionItemModel> }>(),
);

export const updatedItemValueSuccess = createAction(
  '[CollectionItem/API] Updated CollectionItem value [Success]',
  props<{ collectionItem: Update<CollectionItemModel> }>(),
);

export const setCollectionItem = createAction(
  '[CollectionItem/API] Set CollectionItem',
  props<{ collectionItem: CollectionItemModel }>(),
);

export const updatedCollectionItemFailed = createAction(
  '[CollectionItem/API] Updated CollectionItem [Failed]',
  props<{ collectionItemId: string }>(),
);

export const updateCollectionItem = createAction(
  '[CollectionItem/API] Update CollectionItem',
  props<{ collectionItem: CollectionItemModel; showMessage?: boolean }>(),
);

export const changeVilibilityOfItem = createAction(
  '[CollectionItem/API] Change visibility of CollectionItem',
  props<{ collectionItemId: string; hidden: boolean }>(),
);

export const updateCollectionItemValue = createAction(
  '[CollectionItem/API] Update CollectionItem Value',
  props<{ collectionItem: CollectionItemModel; valueRoot: ItemValueRootModel; itemValue: ItemValueModel }>(),
);

export const addValueRootToCollectionItem = createAction(
  '[CollectionItem/API] Add CollectionItem Value Root',
  props<{ collectionItemId: string; fieldRootId: string }>(),
);

export const removeValueRootToCollectionItem = createAction(
  '[CollectionItem/API] Remove CollectionItem Value Root',
  props<{ collectionItemId: string; valueRootId: string }>(),
);

export const updateCollectionItemValueType = createAction(
  '[CollectionItem/API] Update CollectionItem Value Type',
  props<{ collectionItem: CollectionItemModel; valueRoot: ItemValueRootModel; itemValue: ItemValueModel }>(),
);

export const addNewCollectionItemValue = createAction(
  '[CollectionItem/API] Add new CollectionItem Value',
  props<{ collectionItem: CollectionItemModel; valueRoot: ItemValueRootModel; itemValue: ItemValueModel }>(),
);

export const duplicateCollectionItemValue = createAction(
  '[CollectionItem/API] Duplicate CollectionItem Value',
  props<{ collectionItem: CollectionItemModel; valueRoot: ItemValueRootModel; itemValue: ItemValueModel }>(),
);

export const removeCollectionItemValue = createAction(
  '[CollectionItem/API] Delete CollectionItem Value',
  props<{ collectionItem: CollectionItemModel; valueRoot: ItemValueRootModel; itemValue: ItemValueModel }>(),
);

export const cloneCollectionItems = createAction(
  '[CollectionItem/API] Clone CollectionItems',
  props<{ id: string; amount: number }>(),
);
export const clonedCollectionItems = createAction(
  '[CollectionItem/API] Cloned CollectionItems',
  props<{ itemIds: string[] }>(),
);

export const deleteCollectionItem = createAction('[CollectionItem/API] Delete CollectionItem', props<{ id: string }>());

export const deletedCollectionItem = createAction(
  '[CollectionItem/API] Deleted CollectionItem',
  props<{ id: string }>(),
);

export const deleteCollectionItemFailed = createAction(
  '[CollectionItem/API] Delete CollectionItem [Failed]',
  props<{ id: string; err: Error }>(),
);

export const updateTermsOfCollectionItem = createAction(
  '[CollectionItem/API] Update terms of CollectionItem',
  props<{ collectionItemId: string; terms: TermData[] }>(),
);

export const updatedTermToCollectionItem = createAction(
  '[CollectionItem/API] Updated Terms of CollectionItem',
  props<{ collectionItemId: string; terms: TermData[] }>(),
);

export const updatedTermToCollectionItemFailed = createAction(
  '[CollectionItem/API] Updated Terms of CollectionItem [Failed]',
  props<{ collectionItemId: string; terms: TermData[], err: any }>(),
);

export const clearCollectionItems = createAction('[CollectionItem/API] Clear CollectionItems');
