<app-base-page>
  <mat-card class="select-card">
    <mat-card-header>
      <mat-card-title i18n>Kies je applicatie</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div fxLayout="row wrap">
        <button mat-button class="application" (click)="selectApplication(application)" *ngFor="let application of applications">
          {{ application.name }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</app-base-page>
