import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ApplicationsModel } from '../../classes/applications.model';
import * as ApplicationsActions from '../actions/applications.actions';

export const applicationssFeatureKey = 'applicationss';

export interface ApplicationssState extends EntityState<ApplicationsModel> {
  loaded: boolean;
}

export const adapter: EntityAdapter<ApplicationsModel> = createEntityAdapter<ApplicationsModel>();

export const initialState: ApplicationssState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(ApplicationsActions.addedApplicationsSuccess, (state, action) => adapter.addOne(action.applications, state)),
  on(ApplicationsActions.upsertApplications, (state, action) => adapter.upsertOne(action.applications, state)),
  on(ApplicationsActions.addApplicationss, (state, action) => adapter.addMany(action.applicationss, state)),
  on(ApplicationsActions.upsertApplicationss, (state, action) => adapter.upsertMany(action.applicationss, state)),
  on(ApplicationsActions.updatedApplicationsSuccess, (state, action) => adapter.updateOne(action.applications, state)),
  on(ApplicationsActions.deletedApplications, (state, action) => adapter.removeOne(action.id, state)),
  on(ApplicationsActions.loadedApplicationss, (state, action) =>
    adapter.setAll(action.applicationss, { ...state, loaded: true }),
  ),
  on(ApplicationsActions.clearApplicationss, state => adapter.removeAll(state)),
);

export const getApplicationssState = createFeatureSelector<ApplicationssState>('applicationss');

export const applicationssHasLoaded = createSelector(
  getApplicationssState,
  (state: ApplicationssState) => state.loaded,
);

export const getItemById = id => createSelector(getApplicationssState, state => state.entities[id]);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getApplicationssState);
