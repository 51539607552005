import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VideoDialogComponent } from '@dash/randy/shared/components/video-dialog/video-dialog.component';

@Component({
  selector: 'app-global-page-header',
  templateUrl: './global-page-header.component.html',
  styleUrls: ['./global-page-header.component.scss'],
})
export class GlobalPageHeaderComponent implements OnInit {
  @Input() headerText: string;
  @Input() headerTemplate: TemplateRef<any> = null;
  @Input() videoUrl: string = null;
  @Input() tourSteps: string[] = [];

  @Input() buttonBeforeText: TemplateRef<any>;
  @Input() buttonAfterText: TemplateRef<any>;
  @Input() buttonAfterTextAndMenu: TemplateRef<any>;


  constructor(private readonly joyrideService: JoyrideService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  startTour(): void {
    this.joyrideService.startTour({
      steps: this.tourSteps,
      // showPrevButton: false,
      customTexts: {
        next: $localize`Volgende`,
        prev: $localize`Vorige`,
        done: $localize`Afronden`,
      },
      themeColor: '#141619',
    });
  }

  showVideo(): void {
    let dialog = this.dialog.open(VideoDialogComponent, {
      data: {
        videoUrl: this.videoUrl,
      },
      width: '1000px',
      height: '650px',
    });
  }
}
