import { Directive, HostListener, Input } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Directive({
  selector: '[randyAddHistoryRecord]',
})
export class AddHistoryRecordDirective {
  get recordName(): string {
    return 'history/' + this._recordName;
  }

  @Input() set recordName(value: string) {
    this._recordName = value;
  }

  private _recordName: string;

  constructor(private router: Router) {}

  // on click handler
  @HostListener('click', ['$event'])
  addHistoryRecord() {
    if (this._recordName) {
      sessionStorage.setItem(this.recordName, this.router.url);
      return;
    }

    let currentHistory: string[] = JSON.parse(sessionStorage.getItem('history')) ?? [];
    currentHistory.push(this.router.url)
    sessionStorage.setItem('history', JSON.stringify(currentHistory));
  }
}
