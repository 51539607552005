<app-base-page>
  <div class="error-page">
    <img src="https://kleksi.com/img/error_owl.png" alt="broken kleksi" class="brokenImg">
    <mat-card fxLayout="column" fxLayoutAlign="center center">
      <ng-container [ngTemplateOutlet]="errorMessages" [ngTemplateOutletContext]="{code: code$| async}"></ng-container>
      <button mat-raised-button color="accent" [routerLink]="'/dashboard/collection-items'" class="errorBtn"
              i18n>Klik hier om terug naar kleksi te gaan
      </button>
    </mat-card>
  </div>
</app-base-page>


<ng-template #errorMessages let-code="code">
  <ng-container [ngSwitch]="code">
    <ng-container *ngSwitchDefault>
      <h1 i18n>Er is iets mis gegaan!</h1>
      <ng-container [ngTemplateOutlet]="kleksiInformed"></ng-container>
      <p i18n>Neem contact op met <a href="mailto:mail@kleksi.com">mail@kleksi.com</a> indien dit probleem vaker
        voorkomt.</p>
    </ng-container>

    <ng-container *ngSwitchCase="404">
      <h1 i18n>Pagina niet gevonden!</h1>
      <p i18n>Deze pagina bestaat niet.</p>
    </ng-container>

    <ng-container *ngSwitchCase="503">
      <h1 i18n>Server problemen!</h1>
      <p i18n>KLEKSI lijkt geen verbinding te kunnen maken met de servers.</p>
      <ng-container [ngTemplateOutlet]="kleksiInformed"></ng-container>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #kleksiInformed>
  <p i18n>KLEKSI is op de hoogte gesteld van het probleem.</p>
</ng-template>
