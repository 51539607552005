import { Directive, ElementRef, HostListener, OnInit, EventEmitter, Output, Inject, HostBinding } from '@angular/core';
import {
  fromEvent,
  Observable,
  switchMap,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
  of,
  Subject,
  debounce,
  timer,
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {debounceTime, filter} from 'rxjs/operators';

@Directive({
  selector: '[resizableCol]',
})
export class ResizableColDirective {
  @Output()
  readonly resizable = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown').pipe(
    tap(e => {
      e.preventDefault();
      e.stopPropagation();
      this.isResizing$.next(true);
    }),
    switchMap(event => {
      const { left, right, width } = this.elementRef.nativeElement.closest('th')!.getBoundingClientRect();

      const padding = 40;
      if (this.offset === null) {
        this.offset = event.clientX - right;
        console.log('Offset', this.offset);
      }

      console.log('Start width', width);
      return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
        debounceTime(5),
        map(({ clientX }) => {
          const { right, width } = this.elementRef.nativeElement.closest('th')!.getBoundingClientRect();

          let elm = { width: width, diff: clientX - this.offset - right };
          elm.width = Math.round(width + elm.diff - padding);
          if (elm.width < 50) {
            elm.width = 50;
          }
          return elm;
        }),
        filter(({diff}) => diff !== 0),
        distinctUntilChanged(),
        takeUntil(fromEvent(this.documentRef, 'mouseup')),
      );
    }),
  );

  @Output()
  readonly isResizing = new EventEmitter<boolean>();

  isResizing$ = new Subject<boolean>();

  @HostBinding('style.width.px')
  width: number | null = null;
  private offset: number = null;

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    this.isResizing$.pipe(distinctUntilChanged()).subscribe((isResizing: boolean) => {
      this.isResizing.emit(isResizing);
    });

    fromEvent(this.documentRef, 'mouseup')
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.offset = null;
        this.isResizing$.next(false);
      });
  }
}
