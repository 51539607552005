<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
