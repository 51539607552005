import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'Decimal', 'Period'];
    if (
      allowedKeys.indexOf(event.key) !== -1 ||
      // Allow: Ctrl+A
      (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.key === 'c' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.key === 'v' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.key === 'x' && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      event.key.startsWith('Arrow') ||
      event.key === 'Home' ||
      event.key === 'End'
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number or a negative sign and stop the keypress
    if ((!/[0-9\-]/.test(event.key) && event.key !== '.' && event.key !== ',') || /[eE]/.test(event.key)) {
      event.preventDefault();
    }
  }
}
