import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

const DASHBOARD_KEY = 'dashboard-token';

@Injectable({
  providedIn: 'root',
})
export class DashboardSettingsService {
  private defaultSettings: DashboardSettings = { theme: 'sofco-theme-dark', enableSubCollectionsInList: false };

  public dashboardSettings = new BehaviorSubject<DashboardSettings>(this.getSettings());

  public isMobile = new BehaviorSubject(false);
  /**
   * If true the device is a mobile
   */
  isHandset$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(result => result.matches),
      shareReplay(),
    );
    this.isHandset$.subscribe(isHandset => {
      this.isMobile.next(isHandset);
    });
  }

  public saveSettings(settings: DashboardSettings): void {
    window.sessionStorage.removeItem(DASHBOARD_KEY);
    window.sessionStorage.setItem(DASHBOARD_KEY, JSON.stringify(settings));
    this.dashboardSettings.next(this.getSettings());
  }

  public getSettings(): DashboardSettings {
    let settingsRaw = window.sessionStorage.getItem(DASHBOARD_KEY);
    if (!settingsRaw) {
      return this.defaultSettings;
    }
    return JSON.parse(settingsRaw);
  }

  public changeTheme(): void {
    let settings = this.getSettings();
    if (settings.theme === 'sofco-theme-dark') {
      settings.theme = 'sofco-theme-light';
    } else {
      settings.theme = 'sofco-theme-dark';
    }
    this.saveSettings(settings);
  }

  public toggleSubCollectionsInList(value: boolean = null) {
    let settings = this.getSettings();
    if (value === null) {
      settings.enableSubCollectionsInList = !settings.enableSubCollectionsInList;
    } else {
      settings.enableSubCollectionsInList = value;
    }
    this.saveSettings(settings);
  }
}

export interface DashboardSettings {
  theme: 'sofco-theme-dark' | 'sofco-theme-light';
  enableSubCollectionsInList: boolean;
}
