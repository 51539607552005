import { Injectable } from '@angular/core';
import MapTypeStyle = google.maps.MapTypeStyle;
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GoogleMapsSettingsService {
  public readonly snazzyMapsStyle: MapTypeStyle[] = [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 6 }, { color: '#22252b' }, { lightness: 4 }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#22252b' }, { lightness: 1 }],
    },
    { featureType: 'all', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#22252b' }, { lightness: 2 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#22252b' }, { lightness: 7 }, { weight: 1.2 }],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#838383' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#c4c4c4' }],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#aaaaaa' }],
    },
    { featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#22252b' }, { lightness: 2 }] },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#22252b' }, { lightness: 2 }, { visibility: 'on' }],
    },
    { featureType: 'poi.business', elementType: 'geometry', stylers: [{ visibility: 'on' }] },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#6e6e6e' }, { lightness: 0 }],
    },
    { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#22252b' }, { lightness: 1 }],
    },
    { featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{ color: '#575757' }] },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#ffffff' }],
    },
    { featureType: 'road.arterial', elementType: 'labels.text.stroke', stylers: [{ color: '#2c2c2c' }] },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#22252b' }, { lightness: 1 }],
    },
    { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#999999' }] },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#22252b' }, { lightness: 1 }],
    },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#22252b' }, { lightness: 1 }] },
  ];

  private isApiLoaded = false;
  private busyLoading = false;

  public isApiLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {
    this.initApi();
  }

  public async initApi(): Promise<boolean> {
    if (this.busyLoading) {
      return false;
    }
    this.busyLoading = true;
    console.log('Init gmap api');

    if (this.isApiLoaded) {
      return true;
    }

    console.log('Getting gmaps api jsonp');
    this.isApiLoaded = await this.httpClient
      .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyA2NDwIx9TYw2HmPFLZRmqxXcoaBjrcJOE', 'callback')
      .pipe(
        map(() => true),
        catchError(err => {
          console.log(err);
          return of(false);
        }),
      )
      .toPromise();
    if (this.isApiLoaded) {
      this.isApiLoaded$.next(true);
    }
    console.log('gmaps is loaded');
    return this.isApiLoaded;
  }
}
