import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../service/user.service';
import { UserBaseModel } from '../../models/UserBaseModel';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CognitoService } from '@dash/randy/security/service/cognito.service';

@Component({
  selector: 'security-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  @Output() loggedInEvent = new EventEmitter<UserBaseModel>();
  @Output() submittedEvent = new EventEmitter<boolean>();

  @Input() passwordForgotLink: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: UserService,
    private snackbar: MatSnackBar,
    private cognitoService: CognitoService,
  ) {
    console.log('constructor login.component');
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // this.cognitoService.signIn().then(res => {
    //   console.log(res, 'Sign in result');
    //   if (res) {
    //     this.router.navigate(['/dashboard']).then(() => {});
    //   }
    // });
  }

  reloadPage(): void {
    window.location.reload();
  }

  handleLoginBtn() {
    this.cognitoService.signIn().then(res => {
      console.log(res, 'Sign in result');
      if (res) {
        this.router.navigate(['/dashboard']).then(() => {});
      }
    });
  }
}
