import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalstorageRepoService } from '@dash/randy/shared/classes/localstorage-repo.service';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private isDebug$ = new BehaviorSubject(false);

  constructor(private localStorage: LocalstorageRepoService) {
    let isDebugLocal = this.localStorage.getItem('isDebug');

    let isDebug = this.convertDebugValueToBoolean(isDebugLocal);
    this.setDebug(isDebug);
  }

  convertDebugValueToBoolean(value: string): boolean {
    if (!value) {
      return false;
    }
    return value === 'ja';
  }

  convertDebugBooleanToValue(value: boolean): string {
    return value ? 'ja' : 'nee';
  }

  setDebug(value: boolean) {
    this.localStorage.setItem('isDebug', this.convertDebugBooleanToValue(value));
    this.isDebug$.next(value);
  }

  isDebug(): Observable<boolean> {
    return this.isDebug$.asObservable();
  }

  isDebugSync(): boolean {
    return this.isDebug$.getValue();
  }
}
