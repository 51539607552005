<div class="tableToolBar" fxLayout="row" fxLayoutAlign="start center">
  <ng-content></ng-content>
  <form fxFlex="20" [formGroup]="searchForm" novalidate>
    <mat-form-field color="accent" fxFlex="100">
      <input type="text" (keyup)="keyUpEventHandler($event)" formControlName="data" placeholder="Zoeken" i18n-placeholder matInput />
      <button
        *ngIf="searchForm.get('data').value !== ''"
        (click)="clearSearchText()"
        mat-button
        mat-icon-button
        matSuffix
      >
        <mat-icon> close </mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>
