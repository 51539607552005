import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RoleModel } from '@dash/randy/security/models/role.model';
import { RoleService } from '@dash/randy/security/service/role.service';

@Component({
  selector: 'randy-role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.scss'],
})
export class RoleSelectionComponent implements OnInit, OnChanges {
  public roles: RoleModel[] = [];
  allReadSelected: boolean;
  permissionsWithId: { read: string[]; write: string[] } = { read: [], write: [] };
  @Input() permissions: { read: RoleModel[]; write: RoleModel[] } = { read: [], write: [] };
  @Output() permissionsChange = new EventEmitter<{ read: RoleModel[]; write: RoleModel[] }>();
  isPublic: boolean = true;

  constructor(private roleService: RoleService) {}

  ngOnInit(): void {
    this.roles = this.roleService.getRoles();
  }

  toggleAllReadSelection() {
    console.log(this.allReadSelected, 'Is all read selected?');
  }

  permissionsChanged() {
    this.permissionsChange.emit({
      read: this.roleService.convertStringsToModels(this.permissionsWithId.read),
      write: this.roleService.convertStringsToModels(this.permissionsWithId.write),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.permissionsWithId = {
      read: changes['permissions'].currentValue.read.map(role => role.id),
      write: changes['permissions'].currentValue.write.map(role => role.id),
    };
    if (this.isPublic) this.isPublic = this.permissions.read.length === 0 && this.permissions.write.length === 0;
  }

  publicSliderChanged(status: boolean) {
    if (status) {
      console.log('Is public deleting permissions');
      this.permissionsChange.emit({ read: [], write: [] });
    }
  }
}
