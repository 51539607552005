import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { LogService } from '@dash/randy/shared/classes/log.service';
import { UserApplicationModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  public application: UserApplicationModel;

  private urlBase: string;

  constructor(private tokenService: TokenStorageService, private router: Router, private logService: LogService) {
    this.application = tokenService.getSelectedApplication();

    this.urlBase = environment.baseApiUrl;

    logService.debug('AUTHORIZATION SERVICES INIT');
  }

  refetchJWT(): void {
    console.log('Stub for refetching JWT');
    // if (this.user) {
    //   let userId = this.tokenService.getUserId();
    //   let now = new Date();
    //   let validUntil = new Date(this.user.valid_until);
    //   if (validUntil < now) {
    //     this.authService
    //       .refreshToken()
    //       .then(data => {
    //         this.tokenService.saveToken(data.access_token);
    //         this.tokenService.saveUser(data).then(() => {
    //           this.logService.debug(data.refresh_token, 'New refresh token');
    //           this.fireAnalytics.setUserId(userId).then(() => {
    //             const app = this.tokenService.getSelectedApplication();
    //             if (!app) {
    //               // If no app is found redirect to login
    //               this.tokenService.signOut();
    //               this.router.navigate(['/login']);
    //               return;
    //             }
    //             this.fireAnalytics
    //               .setUserProperties({
    //                 appId: app.id,
    //                 appName: app.name,
    //                 userGuid: userId,
    //               })
    //               .then(() => {
    //                 window.location.reload();
    //               });
    //           });
    //         });
    //       })
    //       .catch(error => {
    //         this.logService.error(error, 'Couldnt get the new refresh token');
    //         this.tokenService.signOut();
    //         this.router.navigate(['login']);
    //       });
    //   } else {
    //     this.fireAnalytics.setUserId(userId).then(async result => {
    //       const app = this.tokenService.getSelectedApplication();
    //       if (!app) {
    //         // If no app is found redirect to login
    //         this.tokenService.signOut();
    //         this.router.navigate(['/login']);
    //         return;
    //       }
    //       await this.fireAnalytics.setUserProperties({
    //         appId: this.tokenService.getSelectedApplication().id,
    //         appName: this.tokenService.getSelectedApplication().name,
    //         userGuid: userId,
    //       });
    //     });
    //   }
    // } else {
    //   this.logService.debug('No user found');
    // }
  }

  getApiUrl(admin = false, useGlobalUrl = false): string {
    if (this.application === null) {
      this.logService.warn('Application isnt set!');
      return null;
    }
    if (admin) {
      return environment.baseAdminApiUrl;
    }

    if (useGlobalUrl) {
      return environment.baseApiUrlGlobal + this.application.id + '/admin/';
    }
    return this.urlBase + this.application.id + '/admin/';
  }
}
