import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import Cropper from 'cropperjs';
import { FileManagerItem } from '../../../modules/file-manager/classes/file-manager.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileManagerItemService } from '../../../modules/file-manager/services/file-manager-items.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ImgCdnLinkPipe } from '@dash/randy/shared/components/file-manager-item-icon/img-cdn-link.pipe';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@dash/randy/shared/components/confirm-dialog/confirm-dialog.component';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';
import * as Sentry from '@sentry/angular';
import {FileHelperService} from "../../../modules/file-manager/services/file-helper.service";

@Component({
  selector: 'app-img-file-editor',
  templateUrl: './img-file-editor.component.html',
  styleUrls: ['./img-file-editor.component.scss'],
})
export class ImgFileEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() fileManagerItem: FileManagerItem = null;
  realFileManagerItem: FileManagerItem = null;
  @ViewChild('imageElement', { static: false })
  public imageElement: ElementRef;

  @ViewChild('previewElement', { static: false })
  public previewElement: ElementRef;

  public imgSource: string = null;

  public cropper: Cropper;

  private cropperData: any;
  originalFileLoaded = new BehaviorSubject<boolean>(false);
  cropperLoaded$ = new BehaviorSubject<boolean>(false);

  destroyed$ = new Subject<boolean>();
  private imgCdnLinkPipe: ImgCdnLinkPipe;
  private sentryContext: any = {};

  constructor(
    private fileManagerItemService: FileManagerItemService,
    private dialogRef: MatDialogRef<ImgFileEditorComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { fileManagerItem: FileManagerItem },
  ) {
    if (data && data.fileManagerItem) {
      this.fileManagerItem = data.fileManagerItem;
    }

    this.imgCdnLinkPipe = new ImgCdnLinkPipe();
  }

  ngOnInit(): void {
    console.log(this.fileManagerItem, 'The item for the cropper');

    this.setSentryContext('fileManagerItem', JSON.stringify(this.fileManagerItem));

    if (this.fileManagerItem.metadata && this.fileManagerItem.metadata.originalFileId) {
      this.cropperData = this.fileManagerItem.metadata.cropperData;
      console.log('This image isnt the original');
      this.fileManagerItemService
        .getObject(this.fileManagerItem.metadata.originalFileId)
        .then(newImage => {
          this.realFileManagerItem = newImage;

          this.setSentryContext('realFileManagerItem', JSON.stringify(this.realFileManagerItem));

          console.log(this.realFileManagerItem, 'Original file');
          this.originalFileLoaded.next(true);
        })
        .catch(err => {
          console.log('Something went wrong with loading the original img...');
          console.error(err);
        });
    } else {
      this.realFileManagerItem = this.fileManagerItem;
      this.originalFileLoaded.next(true);
    }
  }

  ngAfterViewInit(): void {
    this.originalFileLoaded.pipe(takeUntil(this.destroyed$)).subscribe(isLoaded => {
      console.log(isLoaded, 'Isloaded');
      if (isLoaded) {
        this.imageElement.nativeElement.src = this.imgCdnLinkPipe.transform(this.realFileManagerItem, false);
        console.log(this.imageElement.nativeElement.src, 'The src');
        console.log('Original img is inserted in src');
      }
    });
  }

  saveImg(): void {
    this.dialogRef.close({ cropperData: this.cropper.getData(), reason: 'fileSaved' });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.clearSentryContext();
  }

  setSentryContext(key: string, data: string) {
    this.sentryContext = {
      ...this.sentryContext,
      [key]: data,
    };

    Sentry.setContext('img file editor component', this.sentryContext);
  }

  clearSentryContext() {
    Sentry.setContext('img file editor component', null);
  }

  loadedImg(): void {
    console.log('Loaded img');

    let cropperLoadSubject = this.cropperLoaded$;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      autoCropArea: 1,
      checkCrossOrigin: false,
      guides: false,
      highlight: false,
      dragMode: 'move',
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: false,
      zoomable: false,
      zoomOnTouch: false,
      checkOrientation: false,
      zoomOnWheel: false,
      preview: this.previewElement.nativeElement,
      data: this.cropperData ?? null,
      ready(event: Cropper.ReadyEvent<HTMLImageElement>): void {
        console.log('Cropper is ready');
        cropperLoadSubject.next(true);
      },
    });
  }

  deleteImg(): void {
    let dialog = this.dialog.open(ConfirmDialogComponent);
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed === 'confirmed') {
        this.dialogRef.close({ reason: 'fileDeleted' });
      }
    });
  }

  loadedImgError(err) {
    // this.dialogRef.close({ reason: 'imgLoadError' });
  }
}
