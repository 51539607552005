import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getItemById, UserssState } from '../../modules/admin-panel/users/store/reducers/users.reducer';
import { UsersModel } from '../../modules/admin-panel/users/classes/users.model';

@Pipe({
  name: 'adminUserEmail',
})
export class AdminUserEmailPipe implements PipeTransform {
  constructor(private userStore: Store<UserssState>) {}

  async transform(userId: string, key = 'email'): Promise<string> {
    return new Promise(resolve => {
      this.userStore.pipe(take(1), select(getItemById(userId))).subscribe(
        (data: UsersModel) => {
          if (data) {
            resolve(data[key]);
          } else {
            resolve(userId);
          }
        },
        error => {
          resolve(userId);
        },
      );
    });
  }
}
