import {AfterContentInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutofocusDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
      if (this.el.nativeElement.tagName.toLowerCase() === 'input' ||
        this.el.nativeElement.tagName.toLowerCase() === 'textarea') {
        this.el.nativeElement.select();
      }
    }, 250);
  }
}
