import {Component, HostBinding} from "@angular/core";

@Component({
  selector: 'blabla[resizableCol]',
  templateUrl: './resizable-col.component.html',
})
export class ResizableColComponent {
  constructor() {}
  @HostBinding('style.width.px')
  width: number | null = null;

  onResize(width: any) {
    this.width = width;
  }
}
