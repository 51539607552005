import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loading-image',
  templateUrl: './loading-image.component.html',
  styleUrls: ['./loading-image.component.scss'],
})
export class LoadingImageComponent implements OnInit {
  @Input() text = '';
  @Input() fullscreen = true;
  @Input() imgWidth: number = 90;
  @Input() bdColor: string = "rgba(41, 44, 51, 0.2)";

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
  }
}
