import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FileManagerItem } from '../../../modules/file-manager/classes/file-manager.model';
import { FileManagerItemService } from '../../../modules/file-manager/services/file-manager-items.service';
import { ImgCdnLinkPipe } from '@dash/randy/shared/components/file-manager-item-icon/img-cdn-link.pipe';
import { BehaviorSubject } from 'rxjs';
import Cropper from 'cropperjs';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';

@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss'],
})
export class ImgCropperComponent implements OnInit {
  @ViewChild('imageElement', { static: false })
  public imageElement: ElementRef;

  @ViewChild('previewElement', { static: false })
  public previewElement: ElementRef;

  public cropperItem: FileManagerItem = null;
  public originalItem: FileManagerItem = null;
  public cropperData: any;
  public isLoading = new BehaviorSubject<boolean>(true);
  public cropper: Cropper;

  private imgCdnLinkPipe: ImgCdnLinkPipe;

  constructor(
    private fileManagerItemService: FileManagerItemService,
    private dialogRef: MatDialogRef<ImgCropperComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { fileManagerItem: FileManagerItem },
  ) {
    this.cropperItem = data.fileManagerItem;

    this.imgCdnLinkPipe = new ImgCdnLinkPipe();
  }

  async ngOnInit(): Promise<void> {
    console.log(this.cropperItem, 'Cropper item');
    if (this.cropperItem.metadata && this.cropperItem.metadata.originalFileId) {
      this.cropperData = this.cropperItem.metadata.cropperData;
      console.log('This image isnt the original');
      this.originalItem = await this.fileManagerItemService.getObject(this.cropperItem.metadata.originalFileId);
    } else {
      this.originalItem = this.cropperItem;
    }

    this.imageElement.nativeElement.src = this.imgCdnLinkPipe.transform(this.originalItem, false);
    this.initCropper();
  }

  loadImageHandler(event: Event) {
    console.log(event, 'Load img event');
  }

  errorImageHandler(err: ErrorEvent) {
    console.log(err, 'Error img event');
  }

  initCropper() {
    const isLoading = this.isLoading;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      autoCropArea: 0,
      guides: false,
      highlight: false,
      dragMode: 'move',
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: false,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      preview: this.previewElement.nativeElement,
      data: this.cropperData ?? null,
      ready(event: Cropper.ReadyEvent<HTMLImageElement>): void {
        console.log('Cropper is ready');
        isLoading.next(false);
      },
    });
  }
}
