import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SentryTransactionService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('Setting transaction name:', event.urlAfterRedirects);
        const transactionName = this.getTransactionName(event.urlAfterRedirects);
        console.log('Setting new transaction name:', transactionName);
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setTransactionName(transactionName);
          scope.setExtras(this.activatedRoute.snapshot.params);
        });
      }
    });
  }

  private getTransactionName(url: string): string {
    // Remove query parameters
    let cleanUrl = url.split('?')[0];

    // Navigate to the root route
    let activeRoute = this.activatedRoute.root;
    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }

    // Replace all route parameters in the URL with a placeholder
    Object.keys(activeRoute.snapshot.params).forEach(key => {
      cleanUrl = cleanUrl.replace(activeRoute.snapshot.params[key], `<${key}>`);
    });

    return cleanUrl.trim();
  }

}
