import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ``,
  selector: 'app-base',
})
export class BaseComponent implements OnDestroy {
  destroyed$ = new Subject<boolean>();

  ngOnDestroy(): void {
    try {
      if (this.destroyed$ && !this.destroyed$.closed) {
        this.destroyed$.next(true);
        this.destroyed$.complete();
      }
    } catch (error) {
      console.error('Couldnt close destroyed?', error);
    }
  }
}
