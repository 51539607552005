import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getItemById } from '../../modules/admin-panel/applications/store/reducers/applications.reducer';
import { ApplicationsModel } from '../../modules/admin-panel/applications/classes/applications.model';

@Pipe({
  name: 'applicationFromId',
})
export class ApplicationFromIdPipe implements PipeTransform {
  constructor(private appStore: Store<ApplicationsModel>) {}

  async transform(appId: string, key = 'name'): Promise<string> {
    return new Promise(resolve => {
      this.appStore.pipe(take(1), select(getItemById(appId))).subscribe(
        (data: ApplicationsModel) => {
          if (data) {
            resolve(data[key]);
          } else {
            resolve(appId);
          }
        },
        error => {
          resolve(appId);
        },
      );
    });
  }
}
