import { Component, Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-custom-snackbar',
  template: `
    <div class="custom-snackbar {{color}}">
      <span>{{ data.message }}</span>
      <button mat-button (click)="handleClick()">
        <ng-container *ngIf="data?.actionText">{{ data.actionText }}</ng-container>
        <ng-container *ngIf="!data?.actionText" i18n>Sluiten</ng-container>
      </button>
    </div>
  `,
  styles: [
    `
      .custom-snackbar {
        font-size: 1rem;
        color: #fff;
        padding: 14px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        &.primary {
          background-color: var(--primary-color);
        }
        &.accent {
          background-color: var(--accent-color);
        }
        &.warn,&.error {
          background-color: var(--warn-color);
        }
      }
    `,
  ],
})
export class CustomSnackbarComponent {
  color: ThemePalette = 'primary';

  constructor(
    private _snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      actionText?: string;
      color?: ThemePalette;
    },
  ) {
    this.color = data.color || this.color;
  }

  handleClick() {
    this.snackBarRef.dismissWithAction();
  }
}
