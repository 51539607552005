import { inject, Pipe, PipeTransform } from '@angular/core';
import { FileManagerItem } from '../../../modules/file-manager/classes/file-manager.model';
import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';
import { FileHelperService } from '../../../modules/file-manager/services/file-helper.service';

@Pipe({
  name: 'imgCdnLink',
})
export class ImgCdnLinkPipe implements PipeTransform {
  public baseUrl = environment.cdnUrl;

  private fileHelperService: FileHelperService = inject(FileHelperService);

  constructor() {}

  transform(item: FileManagerItem | string, thumb = true): string {
    if (!item) {
      return '';
    }
    let itemId = '';
    if (typeof item === 'object') {
      itemId = item.id;
    } else {
      itemId = item;
    }

    if (thumb) {
      return this.fileHelperService.getThumbnailUrl(itemId);
    }

    return this.fileHelperService.getOriginalUrl(itemId);
  }
}
