import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Observable, of} from 'rxjs';
import { BaseComponent } from '@dash/randy/shared/classes/baseComponent';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-button-spinner',
  templateUrl: './button-spinner.component.html',
  styleUrls: ['./button-spinner.component.scss'],
})
export class ButtonSpinnerComponent extends BaseComponent implements OnInit {
  @Input() buttonColor = 'accent';
  @Input() spinnerColor = 'primary';
  @Input() type = 'submit';

  @Output() buttonClicked = new EventEmitter<boolean>();
  @Input() resetStatus: Observable<boolean> = of(false);

  spinnerEnabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.resetStatus.pipe(takeUntil(this.destroyed$)).subscribe(status => {
      this.spinnerEnabled = status;
    });
  }

  clickedButton($event: MouseEvent): void {
    this.spinnerEnabled = true;
    this.buttonClicked.emit(true);
  }
}
