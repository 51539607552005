import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { SecurityModule } from './modules/security/security.module';
import { JoyrideModule } from 'ngx-joyride';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { GoogleMapsSharedModule } from './shared/submodules/google-maps-shared-module/google-maps-shared.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import * as WebFont from 'webfontloader';
import { BehaviorSubject } from 'rxjs';

WebFont.load({
  custom: { families: ['Material Icons', 'Material Icons Outline'] },
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 150, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    SecurityModule.forRoot(),
    AuthorizationModule,
    JoyrideModule.forRoot(),
    GoogleMapsSharedModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
