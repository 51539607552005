import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@dash/randy/security/service/authorization.service';
import { Router } from '@angular/router';
import { CognitoService } from '@dash/randy/security/service/cognito.service';

@Component({
  selector: 'app-select-application',
  templateUrl: './select-application.component.html',
  styleUrls: ['./select-application.component.scss'],
})
export class SelectApplicationComponent implements OnInit {
  public applications: { name: string; id: string }[] = [];

  constructor(
    private authorizationService: AuthorizationService,
    private cognitoService: CognitoService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.applications = this.cognitoService.getApplications();

    if (this.applications.length === 1) {
      this.selectApplication(this.applications[0]);
    }
  }

  selectApplication(application: { name: string; id: string }): void {
    this.cognitoService.selectApplication(application).then(res => {
      sessionStorage.clear();
      this.router.navigate(['/dashboard', 'collection-items']).then(() => {
        window.location.reload();
      });
    });
  }
}
