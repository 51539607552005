import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UsersModel } from '../../classes/users.model';
import * as UsersActions from '../actions/users.actions';

export const userssFeatureKey = 'adminUsers';

export interface UserssState extends EntityState<UsersModel> {
  loaded: boolean;
}

export const adapter: EntityAdapter<UsersModel> = createEntityAdapter<UsersModel>();

export const initialState: UserssState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(UsersActions.addedUsersSuccess, (state, action) => adapter.addOne(action.users, state)),
  on(UsersActions.upsertUsers, (state, action) => adapter.upsertOne(action.users, state)),
  on(UsersActions.addUserss, (state, action) => adapter.addMany(action.users, state)),
  on(UsersActions.upsertUserss, (state, action) => adapter.upsertMany(action.users, state)),
  on(UsersActions.updatedUsersSuccess, (state, action) => adapter.updateOne(action.users, state)),
  on(UsersActions.deletedUsers, (state, action) => adapter.removeOne(action.id, state)),
  on(UsersActions.loadedUserss, (state, action) => adapter.setAll(action.users, { ...state, loaded: true })),
  on(UsersActions.clearUserss, state => adapter.removeAll(state)),
);

export const getUserssState = createFeatureSelector<UserssState>('adminUsers');

export const userssHasLoaded = createSelector(getUserssState, (state: UserssState) => state.loaded);

export const getItemById = id =>
  createSelector(getUserssState, state => {
    if(!state?.entities) return null;
    return state.entities[id];
  });

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getUserssState);
