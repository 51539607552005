import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SmallStatusEnum, StatusService } from '@dash/randy/shared/classes/status.service';

@Directive({
  selector: '[appNgModelChangeDebounced]',
})
export class NgModelChangeDebouncedDirective implements OnDestroy {
  @Output()
  ngModelChangeDebounced = new EventEmitter<any>();
  @Input()
  ngModelChangeDebounceTime = 1500; // optional, 500 default

  @Input() statusKey = 'globalStatus';

  subscription: Subscription;
  private subscriptionStatus: Subscription;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionStatus.unsubscribe();
  }

  constructor(private ngModel: NgModel, private statusService: StatusService) {
    this.subscriptionStatus = this.ngModel.control.valueChanges
      .pipe(skip(1), distinctUntilChanged())
      .subscribe(value => {
        statusService.setStatus(this.statusKey, SmallStatusEnum.loading);
      });
    this.subscription = this.ngModel.control.valueChanges
      .pipe(
        skip(1), // skip initial value
        distinctUntilChanged(),
        debounceTime(this.ngModelChangeDebounceTime),
      )
      .subscribe(value => {
        this.ngModelChangeDebounced.emit(value);
      });
  }
}
