<div class="row" style="width: 100%">
  <div class="col">
    <mat-form-field color="accent" appearance="outline" class="width300">
      <mat-label>{{placeholder}}</mat-label>
      <mat-select [formControl]="typeFormControl">
        <mat-option>
          <ngx-mat-select-search
            ngModel
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="filterDropdownObjectTypes($event)"
            placeholderLabel="Zoeken..."
            i18n-placeholderLabel
            ariaLabel="Zoeken"
            i18n-ariaLabel
            noEntriesFoundLabel="Geen resultaten gevonden"
            i18n-noEntriesFoundLabel
          ></ngx-mat-select-search>
        </mat-option>
        <mat-optgroup *ngFor="let templates of templatesFiltered$ | async | keyvalue"
                      [label]="typeTranslationMap[templates.key]">
          <mat-option *ngFor="let template of templates.value" [value]="template.id">
            {{ template.name | simpleLocale }}
          </mat-option>
        </mat-optgroup>

      </mat-select>
    </mat-form-field>
  </div>
</div>
