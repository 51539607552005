import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DemoService } from '@dash/randy/shared/classes/demo.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appIfDemo]',
})
export class IfDemoDirective implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  constructor(private elementRef: ElementRef, private demoService: DemoService) {}

  ngOnInit(): void {
    this.demoService
      .isDemo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: boolean) => {
        if (!value) {
          this.elementRef.nativeElement.style.display = 'none';
        } else {
          this.elementRef.nativeElement.style.display = '';
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
