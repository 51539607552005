import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CollectionItemModel } from '../../classes/collection-item.model';
import * as CollectionItemActions from '../actions/collection-item.actions';

export const collectionItemsFeatureKey = 'collectionItems';

export interface CollectionItemsState extends EntityState<CollectionItemModel> {
  loaded: boolean;
  hasCustomId: boolean;
}

export const adapter: EntityAdapter<CollectionItemModel> = createEntityAdapter<CollectionItemModel>();

export const initialState: CollectionItemsState = adapter.getInitialState({
  loaded: false,
  hasCustomId: false,
});

export const reducer = createReducer(
  initialState,
  on(CollectionItemActions.addedCollectionItemSuccess, (state, action) => adapter.addOne(action.collectionItem, state)),
  on(CollectionItemActions.upsertCollectionItem, (state, action) => adapter.upsertOne(action.collectionItem, state)),
  on(CollectionItemActions.setCollectionItem, (state, action) => adapter.setOne(action.collectionItem, state)),
  on(CollectionItemActions.addCollectionItems, (state, action) => adapter.addMany(action.collectionItems, state)),
  on(CollectionItemActions.upsertCollectionItems, (state, action) => adapter.upsertMany(action.collectionItems, state)),
  on(CollectionItemActions.updatedCollectionItemSuccess, (state, action) =>
    adapter.updateOne(action.collectionItem, state),
  ),
  on(CollectionItemActions.deletedCollectionItem, (state, action) => adapter.removeOne(action.id, state)),
  on(CollectionItemActions.loadedCollectionItems, (state, action) =>
    adapter.setAll(action.collectionItems, { ...state, loaded: true, hasCustomId: action.hasCustomId }),
  ),
  on(CollectionItemActions.loadedCollectionItemsWithTemplateId, (state, action) => {
    if (state.entities) {
      // for (let key of Object.keys(state.entities)) {
      //   if (state.entities[key].templateId === action.templateId) {
      //     adapter.removeOne(key, state);
      //   }
      // }

      console.log('Remove many', Object.keys(state.entities).filter(key => state.entities[key].templateId === action.templateId));
      // state = adapter.removeMany(Object.keys(state.entities).filter(key => state.entities[key].templateId === action.templateId), state);
    }
    return adapter.upsertMany(action.collectionItems, { ...state, loaded: true });
  }),
  on(CollectionItemActions.clearCollectionItems, state => adapter.removeAll(state)),
);

export const getCollectionItemsState = createFeatureSelector<CollectionItemsState>('collectionItems');

export const collectionItemsHasLoaded = createSelector(
  getCollectionItemsState,
  (state: CollectionItemsState) => state.loaded,
);

export const collectionItemsHasCustomId = createSelector(
  getCollectionItemsState,
  (state: CollectionItemsState) => state.hasCustomId,
);

export const getItemById = (id: string) => createSelector(getCollectionItemsState, state => state.entities[id]);

export const getByCollectionId = id =>
  createSelector(selectAll, objects =>
    objects.filter(
      object => object.collectionIds.findIndex(row => row === id) > -1,
      // return object.collections.id === id;
    ),
  );

export const getByTemplateId = (id: string) => {
  return createSelector(selectAll, objects => objects.filter(object => (!object.type || object.type === 'Object') &&  object.templateId === id));
};

export const getAllItems = () => createSelector(selectAll, objects => objects.filter(object => object.o === true));

export const getAllDropdowns = () => createSelector(selectAll, objects => objects.filter(object => object.o === false));

export const getByCollectionIds = (ids: string[]) =>
  createSelector(selectAll, objects =>
    objects.filter(
      object =>
        object.collectionIds.findIndex(row => {
          for (const id of ids) {
            if (row === id) {
              return true;
            }
          }
          return false;
        }) > -1,
    ),
  );

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getCollectionItemsState);
