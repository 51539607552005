import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageRepoService {
  private storageKey = 'kleksi-';

  private storageAccess = localStorage;

  constructor() {}

  setStorage(storage: Storage) {
    this.storageAccess = storage;
  }

  getItem(key: string, storage: Storage = null): string {
    key = this.storageKey + btoa(key);
    if(!storage) {
        storage = this.storageAccess;
    }
    let item = storage.getItem(key);
    if (!item) {
      return null;
    }
    return atob(item);
  }

  setItem(key: string, item: string, storage: Storage = null) {
    key = this.storageKey + btoa(key);
    item = btoa(item);
    if(!storage) {
        storage = this.storageAccess;
    }
    storage.setItem(key, item);
  }
}
