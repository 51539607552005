<mat-dialog-content [hidden]="(isLoading | async) === true">
  <div class="wrapper" fxLayout="column" fxLayoutAlign="space-evenly start">
    <div fxLayout="row">
      <div fxFlex="80" class="cropperWrapper">
        <img #imageElement src="#" (load)="loadImageHandler($event)" (error)="errorImageHandler($event)" alt="" />
      </div>
      <div fxFlex="20" #previewElement class="img-preview"></div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions [hidden]="(isLoading | async) === true">
  <button mat-raised-button i18n>
    <mat-icon>save</mat-icon>
    Opslaan
  </button>
</mat-dialog-actions>

<ng-container *ngIf="(isLoading | async) === true" #loading>
  <app-spinner [fullscreen]="false"></app-spinner>
</ng-container>
