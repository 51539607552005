import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FieldRootModel } from '../../classes/field-root.model';

export const loadFieldRoots = createAction('[FieldRoot/API] Load FieldRoots');

export const loadedFieldRoots = createAction(
  '[FieldRoot/API] Loaded FieldRoots',
  props<{ fieldRoots: FieldRootModel[] }>(),
);

export const addFieldRoot = createAction('[FieldRoot/API] Add FieldRoot', props<{ fieldRoot: FieldRootModel }>());

export const addedFieldRootSuccess = createAction(
  '[FieldRoot/API] Added FieldRoot [Success]',
  props<{ fieldRoot: FieldRootModel }>(),
);

export const addedFieldRootFailed = createAction(
  '[FieldRoot/API] Added FieldRoot [Failed]',
  props<{ fieldRoot: FieldRootModel }>(),
);

export const upsertFieldRoot = createAction('[FieldRoot/API] Upsert FieldRoot', props<{ fieldRoot: FieldRootModel }>());

export const addFieldRoots = createAction('[FieldRoot/API] Add FieldRoots', props<{ fieldRoots: FieldRootModel[] }>());

export const upsertFieldRoots = createAction(
  '[FieldRoot/API] Upsert FieldRoots',
  props<{ fieldRoots: FieldRootModel[] }>(),
);

export const updatedFieldRootSuccess = createAction(
  '[FieldRoot/API] Updated FieldRoot [Success]',
  props<{ fieldRoot: Update<FieldRootModel> }>(),
);

export const updatedFieldRootFailed = createAction(
  '[FieldRoot/API] Updated FieldRoot [Failed]',
  props<{ fieldRoot: Update<FieldRootModel> }>(),
);

export const updateFieldRoot = createAction('[FieldRoot/API] Update FieldRoot', props<{ fieldRoot: FieldRootModel }>());

export const deleteFieldRoot = createAction('[FieldRoot/API] Delete FieldRoot', props<{ id: string }>());

export const deletedFieldRoot = createAction('[FieldRoot/API] Deleted FieldRoot', props<{ id: string }>());

export const clearFieldRoots = createAction('[FieldRoot/API] Clear FieldRoots');
