import {Component, Inject, LOCALE_ID} from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DashboardSettingsService } from '@dash/randy/dashboard/services/dashboard-settings.service';
import {SentryTransactionService} from "@dash/randy/shared/service/sentry-transaction.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'randy';

  public theme = 'sofco-theme-dark';

  constructor(private _overlayContainer: OverlayContainer, private dashboardSettings: DashboardSettingsService, private sentryTransactions: SentryTransactionService, @Inject(LOCALE_ID) protected localeId: string) {
    _overlayContainer.getContainerElement().classList.add(this.theme);

    dashboardSettings.dashboardSettings.subscribe(dashboardSettingsTmp => {
      this.theme = dashboardSettingsTmp.theme;
      this.changeTheme(this.theme);
    });

    console.log('localize locale', $localize?.locale);
    console.log('localeId', localeId);
  }

  changeTheme(theme: string): void {
    // remove old theme class and add new theme class
    const overlayContainerClasses = this._overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) =>
      item.includes('sofco-theme-'),
    );
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
  }
}
