import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  code$: Observable<string>;
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.code$ = this.route.params.pipe(map(params => params.code));
    console.log('error page');
  }
}
