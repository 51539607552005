import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserBaseModel } from '../models/UserBaseModel';
import { DBCrudService, httpOptions } from '@dash/randy/dashboard/services/db-crud.service';
import { serverUrl } from '@dash/randy/shared/globals';
import { AuthorizationService } from '@dash/randy/security/service/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DBCrudService<UserBaseModel> {
  private apiUrlAuth: string = serverUrl;
  private usersRestUrl: string = this.apiUrlAuth + '/api';

  constructor(router: Router, http: HttpClient, authorizationService: AuthorizationService) {
    super(serverUrl + '/api/users', router, http, authorizationService);
    this.userSubject = new BehaviorSubject<UserBaseModel>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  acceptUser(id: number): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http.put(`${this.usersRestUrl}/users/` + id + '/accept', httpOptions).subscribe(data => {
        resolve(data);
      }),
    );
  }

  public get userValue(): UserBaseModel {
    return this.userSubject.value;
  }

  update(id, params): any {
    return this.http.put(`${this.usersRestUrl}/users/${id}`, params).pipe(
      map(x => {
        // update stored user if the logged in user updated their own record
        if (id === this.userValue.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          localStorage.setItem('user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      }),
    );
  }
}
