import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '@dash/randy/security/service/cognito.service';

@Component({
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private cognitoService: CognitoService, private router: Router) {}

  ngOnInit(): void {
    this.cognitoService.signOut();
    // this.redirectTo('login');
  }

  redirectTo(uri: string): void {
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    //   this.router.navigate([uri]));

    this.router.navigate([uri]).then(() => {
      window.location.reload();
    });
  }
}
