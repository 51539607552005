import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mouseOver]',
})
export class MouseOverDirective {
  constructor(private elementRef: ElementRef) {}

  @Output() mouseOver = new EventEmitter<boolean>();

  @HostListener('mouseover', ['$event'])
  onOver(event: MouseEvent): void {
    console.log(this.elementRef.nativeElement.offsetHeight);
    this.mouseOver.emit(true);
  }
}
