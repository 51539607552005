import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentChange, SelectionChange } from 'ngx-quill';
import { FormGroup } from '@angular/forms';
import { ChatGPTService } from '@dash/randy/shared/submodules/chat-gpt/service/chat-gpt.service';
import { select, Store } from '@ngrx/store';
import { CollectionItemModel } from '../../../modules/crud/collections/collection-item/classes/collection-item.model';
import * as CollectionItemReducer from '../../../modules/crud/collections/collection-item/store/reducers/collection-item.reducer';
import { BaseComponent } from '@dash/randy/shared/classes/baseComponent';
import {firstValueFrom, Observable} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss'],
})
export class QuillComponent extends BaseComponent implements OnInit {
  @Input() set collectionItemId(value: string) {
    this._collectionItemId = value;
    this.collectionItem$ = this.collectionItemStore.pipe(
      select(CollectionItemReducer.getItemById(this._collectionItemId)),
      takeUntil(this.destroyed$),
    );
  }

  private _collectionItemId: string;

  collectionItem$: Observable<CollectionItemModel>;

  @Output() editorTextChanged = new EventEmitter<string>();
  @Input() inputValue: string;
  @Input() disabled: boolean = false;

  @Input() additionalPrompt: string;

  @Input() skipFirst = true;
  private skippedFirst = false;

  constructor(private collectionItemStore: Store<CollectionItemModel>, private chatGPTService: ChatGPTService) {
    super();
  }

  ngOnInit(): void {}

  loadCollectionItem() {}

  changedEditor(
    $event: (ContentChange & { event: 'text-change' }) | (SelectionChange & { event: 'selection-change' }),
  ): void {
    if ($event.event === 'text-change') {
      this.editorTextChanged.emit(this.inputValue);
    }
  }

  changedText(text: string): void {
    // Skip the first because it will fire on startup
    if (this.skipFirst && !this.skippedFirst) {
      this.skippedFirst = true;
      return;
    }

    this.editorTextChanged.emit(text);
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    console.log(event);
    let text = event.clipboardData.getData('text/plain');
    console.log(text);

    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(text));
  }

  async handleChatgpt() {
    console.log('Chat GPT');
    let item = await firstValueFrom(this.collectionItem$);
    console.log(item);

    // get the current text selection
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);
    let text = range.toString();
    let prompt = this.additionalPrompt;
    if(text.length > 0) {
      prompt = `Geneer een ${this.additionalPrompt} voor object.\n${text}`;
    }
    let response = await this.chatGPTService.sendPrompt(item, `Geneer een ${prompt} voor dit object`);
    this.inputValue = response;
  }
}
