import { Directive, ElementRef, HostListener, OnInit, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Directive({
  selector: '[appDraggable]',
})
export class DraggableDirective implements OnInit {
  mouseup = new EventEmitter<MouseEvent>();
  mousedown = new EventEmitter<MouseEvent>();
  mousemove = new EventEmitter<MouseEvent>();

  @Output() coords = new EventEmitter<{ x: number; y: number }>();
  @Output() resetCoords = new EventEmitter<boolean>();
  private active = false;

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event: MouseEvent): void {
    this.mouseup.emit(event);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent): boolean {
    this.mousedown.emit(event);
    return false; // Call preventDefault() on the event
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent): void {
    this.mousemove.emit(event);
  }

  constructor(public element: ElementRef) {
    // this.element.nativeElement.style.position = 'relative';
    // this.element.nativeElement.style.cursor = 'pointer';
  }

  ngOnInit(): void {
    this.mousedown.subscribe(event => {
      this.active = true;
    });

    this.mousemove.subscribe(event => {
      if (this.active) {
        this.coords.emit({ x: event.x, y: event.y });
      }
    });

    this.mouseup.subscribe(event => {
      this.active = false;
      this.resetCoords.emit(true);
    });
  }
}
