import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FieldTemplateModel } from '../../classes/field-template.model';

export const loadFieldTemplates = createAction('[FieldTemplate/API] Load FieldTemplates');
export const loadFieldTemplateById = createAction(
  '[FieldTemplate/API] Load FieldTemplateById',
  props<{ id: string }>(),
);

export const loadedFieldTemplateById = createAction(
  '[FieldTemplate/API] Loaded FieldTemplateById',
  props<{ fieldTemplate: FieldTemplateModel }>(),
);

export const loadedFieldTemplates = createAction(
  '[FieldTemplate/API] Loaded FieldTemplates',
  props<{ fieldTemplates: FieldTemplateModel[] }>(),
);

export const addFieldTemplate = createAction(
  '[FieldTemplate/API] Add FieldTemplate',
  props<{ fieldTemplate: FieldTemplateModel }>(),
);

export const addedFieldTemplateSuccess = createAction(
  '[FieldTemplate/API] Added FieldTemplate [Success]',
  props<{ fieldTemplate: FieldTemplateModel }>(),
);

export const addedFieldTemplateFailed = createAction(
  '[FieldTemplate/API] Added FieldTemplate [Failed]',
  props<{ fieldTemplate: FieldTemplateModel }>(),
);

export const upsertFieldTemplate = createAction(
  '[FieldTemplate/API] Upsert FieldTemplate',
  props<{ fieldTemplate: FieldTemplateModel }>(),
);

export const addFieldTemplates = createAction(
  '[FieldTemplate/API] Add FieldTemplates',
  props<{ fieldTemplates: FieldTemplateModel[] }>(),
);

export const upsertFieldTemplates = createAction(
  '[FieldTemplate/API] Upsert FieldTemplates',
  props<{ fieldTemplates: FieldTemplateModel[] }>(),
);

export const updatedFieldTemplateSuccess = createAction(
  '[FieldTemplate/API] Updated FieldTemplate [Success]',
  props<{ fieldTemplate: Update<FieldTemplateModel> }>(),
);

export const updatedFieldTemplateFailed = createAction(
  '[FieldTemplate/API] Updated FieldTemplate [Failed]',
  props<{ fieldTemplate: Update<FieldTemplateModel> }>(),
);

export const updateFieldTemplate = createAction(
  '[FieldTemplate/API] Update FieldTemplate',
  props<{ fieldTemplate: FieldTemplateModel, mapEntries: boolean }>(),
);

export const deleteFieldTemplate = createAction('[FieldTemplate/API] Delete FieldTemplate', props<{ id: string }>());

export const deletedFieldTemplate = createAction('[FieldTemplate/API] Deleted FieldTemplate', props<{ id: string }>());

export const clearFieldTemplates = createAction('[FieldTemplate/API] Clear FieldTemplates');
