import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
/**
 * @deprecated because the ngx-spinner sucks and its just a gif
 * Use <app-loading-img> instead
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() text = '';
  @Input() fullscreen = true;
  @Input() imgWidth: number = 90;
  @Input() bdColor: string = "rgba(41, 44, 51, 0.2)";

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
  }
}
