import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CollectionModel } from '../../classes/collection.model';

export const loadCollections = createAction('[Collection/API] Load Collections');

export const loadedCollections = createAction(
  '[Collection/API] Loaded Collections',
  props<{ collections: CollectionModel[] }>(),
);

export const addCollection = createAction('[Collection/API] Add Collection', props<{ collection: CollectionModel }>());

export const addedCollectionSuccess = createAction(
  '[Collection/API] Added Collection [Success]',
  props<{ collection: CollectionModel }>(),
);

export const addedCollectionFailed = createAction(
  '[Collection/API] Added Collection [Failed]',
  props<{ collection: CollectionModel }>(),
);

export const upsertCollection = createAction(
  '[Collection/API] Upsert Collection',
  props<{ collection: CollectionModel }>(),
);

export const addCollections = createAction(
  '[Collection/API] Add Collections',
  props<{ collections: CollectionModel[] }>(),
);

export const upsertCollections = createAction(
  '[Collection/API] Upsert Collections',
  props<{ collections: CollectionModel[] }>(),
);

export const updatedCollectionSuccess = createAction(
  '[Collection/API] Updated Collection [Success]',
  props<{ collection: Update<CollectionModel> }>(),
);

export const updatedCollectionFailed = createAction(
  '[Collection/API] Updated Collection [Failed]',
  props<{ collection: Update<CollectionModel> }>(),
);

export const updateCollection = createAction(
  '[Collection/API] Update Collection',
  props<{ collection: CollectionModel }>(),
);

export const deleteCollection = createAction('[Collection/API] Delete Collection', props<{ id: string }>());

export const deletedCollection = createAction('[Collection/API] Deleted Collection', props<{ id: string }>());

export const clearCollections = createAction('[Collection/API] Clear Collections');
