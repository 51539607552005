<app-spinner *ngIf="!(cropperLoaded$ | async)"></app-spinner>

<div class="img-edit-wrapper" [class.hidden]="!(cropperLoaded$ | async)">
  <div class="edit-panel">
    <div class="img-container">
      <img
        #imageElement
        src=""
        (load)="loadedImg()"
        (error)="loadedImgError($event)"
        [alt]="realFileManagerItem?.name"
      />
    </div>
    <div #previewElement class="img-preview"></div>
  </div>
  <div class="cropper-actions">
    <button (click)="cropper.rotate(-45)" mat-icon-button>
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button (click)="cropper.rotate(45)" mat-icon-button>
      <mat-icon>rotate_right</mat-icon>
    </button>

    <button (click)="cropper.scaleX(-1)" mat-icon-button>
      <mat-icon>swap_horiz</mat-icon>
    </button>
    <button (click)="cropper.scaleY(-1)" mat-icon-button>
      <mat-icon>swap_vert</mat-icon>
    </button>

    <button (click)="cropper.reset()" mat-icon-button>
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="dialog-actions">
    <button style="width: 50%" mat-raised-button color="accent" (click)="saveImg()" i18n>
      <mat-icon>save</mat-icon>
      Opslaan
    </button>

    <button style="margin-left: auto" mat-raised-button color="warn" (click)="deleteImg()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
