import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileManagerItem } from '../../../modules/file-manager/classes/file-manager.model';
import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';
import { exhaustMap, firstValueFrom, interval } from 'rxjs';
import { FileManagerItemService } from '../../../modules/file-manager/services/file-manager-items.service';
import { catchError, concatMap, find, tap, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-file-manager-item-icon',
  templateUrl: './file-manager-item-icon.component.html',
  styleUrls: ['./file-manager-item-icon.component.scss'],
})
export class FileManagerItemIconComponent implements OnInit {
  @Input() fileManagerItem: FileManagerItem;
  @Output() fileUploadFailed = new EventEmitter<boolean>();

  public src: string;

  public baseUrl: string;
  broken: boolean = false;

  constructor(private tokenStorage: TokenStorageService, private fileManagerItemService: FileManagerItemService) {}

  ngOnInit(): void {
    this.baseUrl = environment.cdnUrl + this.tokenStorage.getSelectedApplication().id + '/';
    this.src = this.getFileUrl();

    this.polForImgProcessed().catch(err => {
      console.log(err);
      this.setBrokenImg();
    });
  }

  getFileUrl(): string {
    let fullUrl = this.baseUrl + this.fileManagerItem.id;
    if (this.fileManagerItem.thumbnail || this.fileManagerItem.thumbnail === undefined) {
      fullUrl = fullUrl + '/thumbnail';
    }
    return fullUrl;
  }

  setDefaultImg(): void {
    this.src = 'https://kleksi.com/img/kleksi_logo_anim.png';
  }

  async polForImgProcessed() {
    console.log('Polling for image processed', this.fileManagerItem);
    if (this.fileManagerItem.uploadedAt) {
      return;
    }

    for (let i = 0; i < 10; i++) {
      console.log('Polling for image processed: ' + i, this.fileManagerItem.id);
      await new Promise(resolve => setTimeout(resolve, 5000));

      let item = await this.fileManagerItemService.getObject(this.fileManagerItem.id).catch(err => {
        console.log(err, 'Upload failed');
        this.fileUploadFailed.next(true);
        this.setBrokenImg();
        return null;
      });
      if (!item) {
        return;
      }
      if (item.uploadedAt) {
        this.fileManagerItem = item;
        return;
      }
    }
    throw new Error('Could not verify image processed');
    // return new Promise((resolve, reject) => {
    //   interval(2000)
    //     .pipe(
    //       exhaustMap(() =>
    //         this.fileManagerItemService.getObject(this.fileManagerItem.id).catch(err => {
    //           console.log(err, 'Upload failed');
    //           this.fileUploadFailed.next(true);
    //           reject('Upload failed');
    //           return null;
    //         }),
    //       ), // <== switch subscription to `getUrlById`
    //       find(x => x === null || x.uploadedAt !== undefined),
    //       timeout(3 * 30 * 1000),
    //       catchError(err => {
    //         console.log(err, 'Upload failed');
    //         this.fileUploadFailed.next(true);
    //         reject('Upload failed');
    //         return null;
    //       }),
    //     )
    //     .subscribe((i: FileManagerItem) => {
    //       if (i === null) {
    //         reject('Upload failed');
    //         return;
    //       }
    //       console.log(i, 'Image is uploaded');
    //       this.fileManagerItem = i;
    //       resolve(true);
    //     });
    // });
  }

  setBrokenImg() {
    this.broken = true;
    this.src = 'https://kleksi.com/img/error_owl.png';
  }
}
