import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';
import Chart from "chart.js/auto";

if (environment.production) {
  enableProdMode();
}

let dialogShownTimeout: any;

const complex_hash_find_regex = /\/[a-zA-Z0-9_]*((?:[a-zA-Z_][a-zA-Z0-9_]*)?[0-9](?:[a-zA-Z0-9_]*[a-zA-Z_])?[a-zA-Z0-9_]*)(\/|$)/g;

Sentry.init({
  dsn: 'https://8147a8042e28f19dfc0abc2b118d8271@o4506468623974400.ingest.sentry.io/4506469023481856',
  enabled: environment.sentry.enabled,
  release: environment.sentry.release,
  dist: $localize?.locale,
  tracePropagationTargets: ['localhost', 'kleksi.dev', 'kleksi.com'],
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  debug: environment.sentry.debug,
  environment: environment.sentry.environment,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (hint?.originalException instanceof HttpErrorResponse) {
      console.log('Convert http error response to exception');
      hint.originalException = new Error(hint.originalException.message);
    }

    console.error(event, 'Something went wrong in the application');

    if (event.exception) {
      // Timeout ensures only the lasty dialog is sent if multiple errors are received
      if (dialogShownTimeout) {
        clearTimeout(dialogShownTimeout);
      }
      dialogShownTimeout = setTimeout(() => {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: $localize`nl`,
          title: $localize`Er is een fout opgetreden`,
          subtitle: $localize`Het team is op de hoogte gesteld.`,
          subtitle2: $localize`Help ons door een melding te maken.`,
          labelComments: $localize`Wat is er gebeurd?`,
          labelClose: $localize`Sluiten`,
          labelSubmit: $localize`Verstuur`,
          labelName: $localize`Naam`,
          labelEmail: $localize`E-mail`,
          labelSuccess: $localize`Melding verzonden`,
          successMessage: $localize`Bedankt voor het melden van de fout`,
          errorFormEntry: $localize`Niet alle velden zijn ingevuld`,
        });
      }, 1500);
    }
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentry.traceSampleRate,
});

Chart.defaults.color = '#878f9e';


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    console.log('Bootstrap error');
    console.error(err);
  });
