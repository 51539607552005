const backbone = require('./vars/backbone.json'); //with path
const version = require('./vars/version.json'); //with path

export const environment = {
  production: true,
  sentry: {
    environment: 'production',
    enabled: true,
    traceSampleRate: 0.1,
    debug: false,
    release: version.version ?? require('../../../../../package.json').version,
  },
  baseApiUrl: `https://eu.api.${backbone.domain}/apps/`,
  baseApiUrlGlobal: `https://global.api.${backbone.domain}/apps/`,
  baseAdminApiUrl: `https://global.api.${backbone.domain}/owner/`,
  publicAdminApiUrl: `https://global.api.${backbone.domain}/admin/`,
  cdnUrl: `https://eu.cdn.${backbone.domain}/`,
  forgotUrl: `https://global.api.${backbone.domain}/forgotpassword/`,
  qrUrl: `https://${backbone.short_domain}/`,
  baseDashboardDomain: `https://dash.${backbone.domain}/`,
  detailUrl: `https://eu.detail.${backbone.domain}/`,
  logWithDate: true,
  appVersion: version.version ?? require('../../../../../package.json').version + '-p',
  aws: {
    allowedApiUrls: [`https://global.api.${backbone.domain}`].concat(
      backbone.servers.map(server => `https://${server.id}.api.${backbone.domain}`),
    ),
    cognito: {
      issuer: `https://${backbone.cognito_user_pool_endpoint}`, // region + userpool id
      redirectUri: `https://dash.${backbone.domain}/%LOCALE%/callback/login`, // callback url
      cognitoTokenURL: `https://auth.${backbone.domain}`, // hosted ui
      clientId: `${backbone.cognito_user_pool_client_id}`,
      logoutUrl: `https://auth.${backbone.domain}/logout?client_id=${backbone.cognito_user_pool_client_id}&logout_uri=https://dash.${backbone.domain}/%LOCALE%/callback/logout`,
      // cognitoAppClientSecret: '1utqodi1243l0hpcnscqtnm85rlv8lkcg8nl5htd2g93iob3m34t', // idk
      scope: 'email openid profile', //offline_access
      responseType: 'code',
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
    },
  },
};
