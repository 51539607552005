import {AfterViewInit, Component, ElementRef, inject, Inject, Optional, ViewChild} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {FileManagerItem} from "../../../modules/file-manager/classes/file-manager.model";
import {FileManagerItemService} from "../../../modules/file-manager/services/file-manager-items.service";
import {MediaPlayer, MediaPlayerClass} from "dashjs";
import {FileHelperService} from "../../../modules/file-manager/services/file-helper.service";

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent implements AfterViewInit{
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  videoFile = this.data?.fileManagerItem;
  protected player: MediaPlayerClass;

    constructor(
      private fileHelperService: FileHelperService,
      private dialogRef: MatDialogRef<VideoViewerComponent>,
      @Inject(MAT_DIALOG_DATA) @Optional() private data: { fileManagerItem: FileManagerItem },
    ) {}

    ngAfterViewInit() {
      if(this.videoFile.type === 'VIDEO') {
        this.player = MediaPlayer().create();
        const url = this.fileHelperService.getManifestUrl(this.videoFile.id);
        this.player.initialize(this.videoPlayer.nativeElement, url, true);
      }
    }

  closeDialog() {
    this.dialogRef.close();
  }
}
