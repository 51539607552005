<h1 mat-dialog-title i18n>Instructievideo</h1>

<div class="videoWrapper">
  <iframe
    [src]="videoUrl | safe: 'resourceUrl'"
    title="Kleksi instructievideo"
    i18n-title
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
<!--<div mat-dialog-actions>-->
<!--  <button mat-button (click)="confirmed()">Ja!</button>-->
<!--  <button mat-button mat-dialog-close>Nee</button>-->
<!--</div>-->
