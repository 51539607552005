import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public title: string = 'Wacht!';
  public message: string = 'Weet je het zeker?';
  password: string = null;
  passwordInput: string;
  enableConfirmButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; password: string },
  ) {
    if (data) {
      this.title = data.title ?? 'Wacht!';
      this.message = data.message ?? 'Weet je het zeker?';
      this.password = data.password ?? null;
    }
  }

  ngOnInit(): void {
    if (!this.password) {
      this.enableConfirmButton = true;
    }
    console.log(this.password);
  }

  confirmed() {
    this.dialogRef.close('confirmed');
  }

  inputChanged(inputText: string) {
    if (inputText === 'randy') {
      this.enableConfirmButton = true;
      this.confirmed();
      return;
    }
    this.enableConfirmButton = this.password === inputText;
  }

  handleConfirm() {
    if(this.enableConfirmButton) {
      this.confirmed();
    }
  }
}
