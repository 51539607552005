import { NgModule } from '@angular/core';
import { SharedModule } from '@dash/randy/shared/shared.module';
import { LoginComponent } from './pages/account/login/login.component';
import { SecurityModule } from '@dash/randy/security/security.module';
import { SelectApplicationComponent } from './pages/account/select-application/select-application.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginComponent, SelectApplicationComponent],
  imports: [SharedModule, SecurityModule, RouterModule],
})
export class AuthorizationModule {}
