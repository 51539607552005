<div class="quill">
  <quill-editor
    (ngModelChangeDebounced)="changedText($event)"
    appNgModelChangeDebounced
    [disabled]="disabled"
    [ngModelChangeDebounceTime]="2000"
    (paste)="handlePaste($event)"
    [(ngModel)]="inputValue"
    [style]="'height: 400px;'"
    placeholder="Typ hier uw tekst..."
    i18n-placeholder
    class="editorCntr"
  >
    <div quill-editor-toolbar>
      <!--    <span class="ql-formats">-->
      <!--      <select class="ql-font">-->
      <!--        <option selected></option>-->
      <!--        <option value="serif"></option>-->
      <!--        <option value="monospace"></option>-->
      <!--      </select>-->
      <!--      <select class="ql-size">-->
      <!--        <option value="small"></option>-->
      <!--        <option selected></option>-->
      <!--        <option value="large"></option>-->
      <!--        <option value="huge"></option>-->
      <!--      </select>-->
      <!--    </span>-->

      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>

      <!--      <span class="ql-formats">-->
      <!--      <select class="ql-color"></select>-->
      <!--      <select class="ql-background"></select>-->
      <!--    </span>-->
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
        <button class="ql-image"></button>
      </span>
        <span appIfDebug class="ql-formats">
            <button *ngIf="collectionItem$ | async" class="ql-chatgpt" (click)="handleChatgpt()" style="transform: scale(0.8);"
            matTooltip="AI"
            i18n-matTooltip>
              <mat-icon>
                chat_bubble_outlined
              </mat-icon>
            </button>
        </span>
    </div>

    <!--    (onEditorChanged)="changedEditor($event)" -->
  </quill-editor>
</div>
