import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@dash/randy/shared/components/confirm-dialog/confirm-dialog.component';

@Directive({
  selector: '[appConfirmDialog]',
})
export class ConfirmDialogDirective {
  @Input() title: string = null;
  @Input() message: string = null;
  @Input() password: string = null;
  @Output() afterConfirm = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    let dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.title,
        message: this.message,
        password: this.password,
      },
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed === 'confirmed') {
        this.afterConfirm.emit(true);
      }
    });
  }
}
