import {
  AutoIncrementData,
  DateData,
  DateTimeData,
  DimensionsData,
  DropdownData,
  FileData,
  GeoCoords,
  ItemValueDataModel,
  NumberData,
  OptionData,
  ProcedureData,
  ReminderData,
  SpecialLocale,
  UrlData,
} from '../../../../modules/crud/items/item-value/classes/item-value.model';

export abstract class BaseDataTypeClass {
  abstract validate(data: ItemValueDataModel, locale?: string): boolean;

  abstract generateDefaultDataObject(locale?: string): ItemValueDataModel;

  abstract getDataAsString(data: ItemValueDataModel, locale?: string): string;
}

export class UrlDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: SpecialLocale[], locale?: string): string {
    if (!locale) {
      return (data[0].data as UrlData).name;
    }
    let localeData = data.find(row => row.locale === locale).data as UrlData;
    return localeData.name;
  }

  validate(data: SpecialLocale[], locale: string = null): boolean {
    if (!locale) {
      return data.length > 0;
    }
    let urlData = data.find(row => row.locale === locale).data as UrlData;
    return urlData.name.length > 0 && urlData.url.length > 0;
  }

  generateDefaultDataObject(locale: string): SpecialLocale[] {
    return [
      {
        locale: locale,
        data: { download: false, newTab: false, name: '', url: '' } as UrlData,
      },
    ] as SpecialLocale[];
  }
}

export class TextDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: SpecialLocale[], locale?: string): string {
    if (!locale) {
      return data[0].data as string;
    }
    let localeData = data.find(row => row.locale === locale);
    if (localeData) {
      return localeData.data as string;
    }
    return '';
  }

  validate(data: SpecialLocale[], locale: string = null): boolean {
    if (!locale) {
      return data.length > 0;
    }
    let textData = data.find(row => row.locale === locale).data as string;
    return textData.length > 0;
  }

  generateDefaultDataObject(locale: string): SpecialLocale[] {
    return [
      {
        locale: locale,
        data: '',
      },
    ] as SpecialLocale[];
  }
}

export class GeoCoordsDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: GeoCoords, locale?: string): string {
    return `${data.latitude}, ${data.longitude}`;
  }

  validate(data: GeoCoords): boolean {
    return data.latitude > 0 && data.longitude > 0;
  }

  generateDefaultDataObject(): GeoCoords {
    return { longitude: 0, latitude: 0 } as GeoCoords;
  }
}

export class AutoIncrementDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: AutoIncrementData): string {
    return `${data.number}`;
  }

  validate(data: AutoIncrementData): boolean {
    return data.number > 0;
  }

  generateDefaultDataObject(): AutoIncrementData {
    return { number: 0, new: false } as AutoIncrementData;
  }
}

export class DropdownDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: DropdownData): string {
    return data.collectionItemId;
  }

  validate(data: DropdownData): boolean {
    return data.collectionItemId && data.collectionItemId.length > 0;
  }

  generateDefaultDataObject(): DropdownData {
    return { collectionItemId: null } as DropdownData;
  }
}

export class DateTimeDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: DateTimeData): string {
    return data.utc;
  }

  validate(data: DateTimeData): boolean {
    return data.utc.length > 0;
  }

  generateDefaultDataObject(): DateTimeData {
    return { utc: new Date().toUTCString() } as DateTimeData;
  }
}

export class DateDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: DateData): string {
    return `${data.day}-${data.month}-${data.year}`;
  }

  validate(data: DateData): boolean {
    return data !== null;
  }

  generateDefaultDataObject(): DateTimeData {
    return { utc: new Date().toUTCString() } as DateTimeData;
  }
}

export class ReminderDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: ReminderData): string {
    return data.receivers.join(', ');
  }

  validate(data: ReminderData): boolean {
    return data.dateTime.utc.length > 0 && data.receivers.length > 0;
  }

  generateDefaultDataObject(): ReminderData {
    return { dateTime: { utc: new Date().toUTCString() }, receivers: [] } as ReminderData;
  }
}

export class ProcedureDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: ProcedureData): string {
    return data.procedureId;
  }

  validate(data: ProcedureData): boolean {
    return data.procedureId && data.procedureId.length > 0;
  }

  generateDefaultDataObject(): ProcedureData {
    return { procedureId: null } as ProcedureData;
  }
}

export class DimensionsDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: DimensionsData): string {
    return `${data.length} x ${data.width} x ${data.height} ${data.unit}`;
  }

  validate(data: DimensionsData): boolean {
    return true;
  }

  generateDefaultDataObject(): DimensionsData {
    return { unit: 'cm', width: null, length: null, height: null } as DimensionsData;
  }
}

export class NumberDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: NumberData): string {
    return data.value;
  }

  validate(data: NumberData): boolean {
    return !!data.value;
  }

  generateDefaultDataObject(): NumberData {
    return { value: null } as NumberData;
  }
}

export class FileDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: FileData): string {
    console.error('Not implemented',data);
    return data.fileId;
  }

  validate(data: FileData): boolean {
    return !!data.fileId;
  }

  generateDefaultDataObject(): FileData {
    return { fileId: null } as FileData;
  }
}


export class OptionDataTypeClass extends BaseDataTypeClass {
  getDataAsString(data: OptionData): string {
    return data.key;
  }

  validate(data: OptionData): boolean {
    console.log('OptionDataTypeClass.validate',data);
    return !!data.key
  }

  generateDefaultDataObject(): OptionData {
    return { key: null } as OptionData;
  }
}
