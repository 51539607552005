import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CollectionModel } from '../../classes/collection.model';
import * as CollectionActions from '../actions/collection.actions';

export const collectionsFeatureKey = 'collections';

export interface CollectionsState extends EntityState<CollectionModel> {
  loaded: boolean;
}

export const adapter: EntityAdapter<CollectionModel> = createEntityAdapter<CollectionModel>();

export const initialState: CollectionsState = adapter.getInitialState({
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(CollectionActions.addedCollectionSuccess, (state, action) => adapter.addOne(action.collection, state)),
  on(CollectionActions.upsertCollection, (state, action) => adapter.upsertOne(action.collection, state)),
  on(CollectionActions.addCollections, (state, action) => adapter.addMany(action.collections, state)),
  on(CollectionActions.upsertCollections, (state, action) => adapter.upsertMany(action.collections, state)),
  on(CollectionActions.updatedCollectionSuccess, (state, action) => adapter.updateOne(action.collection, state)),
  on(CollectionActions.deletedCollection, (state, action) => adapter.removeOne(action.id, state)),
  on(CollectionActions.loadedCollections, (state, action) =>
    adapter.setAll(action.collections, { ...state, loaded: true }),
  ),
  on(CollectionActions.clearCollections, state => adapter.removeAll(state)),
);

export const getCollectionsState = createFeatureSelector<CollectionsState>('collections');

export const collectionsHasLoaded = createSelector(getCollectionsState, (state: CollectionsState) => state.loaded);

export const getItemById = id => createSelector(getCollectionsState, state => state.entities[id]);

export const getRootCollections = () =>
  createSelector(selectAll, objects => objects.filter(object => !object.parentCollectionId));

export const getRootCollectionsWithChildCount = (selector = selectAll) =>
  createSelector(selector, objects =>
    objects
      .filter(object => !object.parentCollectionId)
      .map(collection => {
        return {
          ...collection,
          childCount: objects.filter(object => object.parentCollectionId === collection.id).length,
        };
      }),
  );

export const getCollectionsByParentId = id =>
  createSelector(selectAll, objects => objects.filter(object => object.parentCollectionId === id));

export const getCollectionsByParentIdAndCount = id =>
    createSelector(selectAll, objects =>
        objects
        .filter(object => object.parentCollectionId === id)
        .map(collection => {
            return {
            ...collection,
            childCount: objects.filter(object => object.parentCollectionId === collection.id).length,
            };
        }),
    );
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(getCollectionsState);
