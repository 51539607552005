import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-and-drop-overlay',
  templateUrl: './drag-and-drop-overlay.component.html',
  styleUrls: ['./drag-and-drop-overlay.component.scss'],
})
export class DragAndDropOverlayComponent implements OnInit {
  public show = false;

  constructor() {}

  ngOnInit(): void {}

  changeOverlay(show: boolean): void {
    this.show = show;
  }
}
