import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FileHelperService {
  public baseUrl = environment.cdnUrl;

  constructor(private tokenStorage: TokenStorageService) {
    this.baseUrl += this.tokenStorage.getSelectedApplication().id + '/';
  }

  getThumbnailUrl(itemId: string): string {
    return this.baseUrl + itemId + '/thumbnail';
  }

  getOriginalUrl(itemId: string): string {
    return this.baseUrl + itemId + '/original';
  }

  getVideoUrl(itemId: string): string {
    return this.baseUrl + itemId + '/video.mp4';
  }

  getManifestUrl(itemId: string): string {
    return this.baseUrl + itemId + '/manifest.mpd';
  }

  getAudioUrl(itemId: string): string {
    return this.baseUrl + itemId + '/audio.mp3';
  }
}
