<ng-container *ngIf="!broken; else brokenIcon">
  <ng-container *ngIf="fileManagerItem.type === 'IMAGE' || fileManagerItem.type === 'VIDEO'">
    <img
      *ngIf="fileManagerItem.uploadedAt"
      [src]="src"
      alt="{{ fileManagerItem.name }}"
      (error)="setBrokenImg()"
      class="imgPreview"
      loading="lazy"
    />

    <img
      *ngIf="!fileManagerItem.uploadedAt"
      class="imgPreview placeHolder"
      src="https://kleksi.com/img/kleksi_logo_anim.png"
      alt="Afbeelding is aan het laden"
      i18n-alt
    />
  </ng-container>

    <ng-container *ngIf="fileManagerItem.type === 'AUDIO'">
        <mat-icon class="imgPreview placeHolder">volume_up</mat-icon>
    </ng-container>

    <ng-container *ngIf="fileManagerItem.type === 'DOCUMENT'">
        <mat-icon class="imgPreview placeHolder">picture_as_pdf</mat-icon>
    </ng-container>

    <ng-container *ngIf="fileManagerItem.type === 'OTHER'">
        <mat-icon class="imgPreview placeHolder">description</mat-icon>
    </ng-container>

    <ng-container *ngIf="fileManagerItem.type === 'ARCHIVE'">
        <mat-icon class="imgPreview placeHolder">archive</mat-icon>
    </ng-container>


</ng-container>


<ng-template #brokenIcon>
  <img
    class="imgPreview placeHolder"
    src="https://kleksi.com/img/error_owl.png"
    alt="Afbeelding kon niet geladen worden"
    i18n-alt
  />
</ng-template>
