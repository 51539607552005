import { FieldValueType } from '../../../modules/crud/fields/field-value/classes/field-value.model';
import {
  AutoIncrementDataTypeClass,
  BaseDataTypeClass,
  DateTimeDataTypeClass,
  DimensionsDataTypeClass,
  DropdownDataTypeClass,
  FileDataTypeClass,
  GeoCoordsDataTypeClass,
  NumberDataTypeClass, OptionDataTypeClass,
  ProcedureDataTypeClass,
  ReminderDataTypeClass,
  TextDataTypeClass,
  UrlDataTypeClass,
} from '@dash/randy/shared/service/collection-items/data-types/base-data-type-class';

export class DataTypeHelperFactory {
  static getDataTypeHelper(type: FieldValueType): BaseDataTypeClass {
    switch (type) {
      case FieldValueType.TEXT:
        return new TextDataTypeClass();
      case FieldValueType.BIG_TEXT:
        return new TextDataTypeClass();
      case FieldValueType.GEO_COORDS:
        return new GeoCoordsDataTypeClass();
      case FieldValueType.AUTO_INCREMENT:
        return new AutoIncrementDataTypeClass();
      case FieldValueType.DROPDOWN:
        return new DropdownDataTypeClass();
      case FieldValueType.DATE_TIME:
        return new DateTimeDataTypeClass();
      case FieldValueType.DATE:
        return new DateTimeDataTypeClass();
      case FieldValueType.URL:
        return new UrlDataTypeClass();
      case FieldValueType.REMINDER:
        return new ReminderDataTypeClass();
      case FieldValueType.PROCEDURE:
        return new ProcedureDataTypeClass();
      case FieldValueType.DIMENSIONS:
        return new DimensionsDataTypeClass();
      case FieldValueType.NUMBER:
        return new NumberDataTypeClass();
      case FieldValueType.FILE:
        return new FileDataTypeClass();
      case FieldValueType.OPTION:
        return new OptionDataTypeClass();
      default:
        throw new Error(`Unknown data type: ${type}`);
    }
  }
}
