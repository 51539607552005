import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogService } from '@dash/randy/shared/classes/log.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private statussus = {};

  constructor(private logService: LogService) {}

  getStatus(key: string): EventEmitter<SmallStatusEnum> {
    this.initNewStatus(key);
    return this.statussus[key];
  }

  private initNewStatus(key: string): void {
    if (!this.statussus[key]) {
      this.statussus[key] = new EventEmitter<SmallStatusEnum>();
      this.statussus[key].next(SmallStatusEnum.nothing);
    }
  }

  setStatus(key: string, status: SmallStatusEnum): void {
    this.initNewStatus(key);
    this.statussus[key].next(status);
    // this.logService.info('new status', key, status);
  }
}

export enum SmallStatusEnum {
  nothing,
  loading,
  done,
  error,
}
